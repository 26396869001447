export enum Rarity {
	Common,
	Uncommon,
	Rare,
	Epic,
	Legendary,
}

export enum ItemCode {
	None,
	Money,
	Gem,
	LevelCrystal,
	GoldenIdol,
	PirateToken,
	Token4,
	Token5,

	RankBRock,		// beta
	RankARock,		// alpha
	RankSRock,		// sigma
	RankSSRock,		// theta
	RankSSSRock,	// zeta

	Score1Rock,	// genius
	Score2Rock,	// pro
	Score3Rock,	// expert
	Score4Rock,	// master
	Score5Rock,	// grandmaster

	Kill1Rock,		// rage
	Kill2Rock,		// blood
	Kill3Rock,	// death
	Kill4Rock,	// carnage
	Kill5Rock,	// extinction


	SpinelOre,
	GarnetOre,
	RubyOre,

	CopperOre,
	CitrineOre,
	GoldOre,

	PeridotOre,
	TurquoiseOre,
	EmeraldOre,

	LapisLazuliOre,
	AquamarineOre,
	SapphireOre,

	QuartzOre,
	AmethystOre,
	DiamondOre,

	IronOre,
	SilverOre,
	MythrilOre,

	SharkOrb,
	DolphinOrb,
	WhaleOrb,

	BlueSharkStone,
	BlueSharkGem,
	GreatWhiteSharkStone,
	GreatWhiteSharkGem,
	MegalodonStone,
	MegalodonGem,
	HammerheadStone,
	HammerheadGem,
	HammeredHeadStone,
	HammeredHeadGem,
	WhammyHeadStone,
	WhammyHeadGem,
	KillerWhaleStone,
	KillerWhaleGem,
	SerialKillerWhaleStone,
	SerialKillerWhaleGem,
	PsychoKillerWhaleStone,
	PsychoKillerWhaleGem,


	BumpOnHead,
	Bungalow,
	Bandaid,
	BandaidX,
	Glasses,
	SpiralGlasses,
	Monocle,
	GentlemanMustache,
	RedNose,
	Scar1,
	ScarX,
	Warpaint1,
	Warpaint2,
	Warpaint4,

	BpBumpOnHead,
	BpBungalow,
	BpBandaid,
	BpBandaidX,
	BpGlasses,
	BpSpiralGlasses,
	BpMonocle,
	BpGentlemanMustache,
	BpRedNose,
	BpScar1,
	BpScarX,
	BpWarpaint1,
	BpWarpaint2,
	BpWarpaint4,

	BikerSunglasses,
	Saber,
	SharkSaberBlue,
	SharkSaberRed,
	SharkSaberYellow,
	SharkSaberPurple,
	BpBikerSunglasses,
	BpSaber,
	BpSharkSaberBlue,
	BpSharkSaberRed,
	BpSharkSaberYellow,
	BpSharkSaberPurple,

	BottlenoseDolphinStone,
	BottlenoseDolphinGem,
	CommonDolphinStone,
	CommonDolphinGem,
	SpinnerDolphinStone,
	SpinnerDolphinGem,

	BeachBall,
	Hoop,
	AmericanFootball,
	BpBeachBall,
	BpHoop,
	BpAmericanFootball,

	Sword,
	DolphinSwordBlack,
	DolphinSwordPurple,
	DolphinSwordTeal,
	DolphinSwordGreen,
	BpSword,
	BpDolphinSwordBlack,
	BpDolphinSwordPurple,
	BpDolphinSwordTeal,
	BpDolphinSwordGreen,

	PufferfishOrb,
	NarwhalOrb,

	PufferfishStone,
	PufferfishGem,
	PorcupinefishStone,
	PorcupinefishGem,
	OnifuguStone,
	OnifuguGem,
	NarwhalStone,
	NarwhalGem,
	BelugaStone,
	BelugaGem,
	ArchBelugaStone,
	ArchBelugaGem,

	CarpOrb,
	TurtleOrb,

	GoldfishStone,
	GoldfishGem,
	GoldenfishStone,
	GoldenfishGem,
	DiamondfishStone,
	DiamondfishGem,
	GreenSeaTurtleStone,
	GreenSeaTurtleGem,
	LeatherbackStone,
	LeatherbackGem,
	AlligatorSnappingTurtleStone,
	AlligatorSnappingTurtleGem,

	BlueCap,
	Shield,
	BpBlueCap,
	BpShield,

	CephalopodOrb,
	MythicalOrb,

	HumpbackWhaleStone,
	HumpbackWhaleGem,
	BlueWhaleStone,
	BlueWhaleGem,
	JomamaStone,
	JomamaGem,
	DumboOctopusStone,
	DumboOctopusGem,
	TakoStone,
	TakoGem,
	KrakenStone,
	KrakenGem,

	RayOrb,
	EelOrb,
	KnifefishOrb,

	MantaRayStone,
	MantaRayGem,
	MantraRayStone,
	MantraRayGem,
	BlueRayStone,
	BlueRayGem,
	MorayEelStone,
	MorayEelGem,
	GulperEelStone,
	GulperEelGem,
	ElectricEelStone,
	ElectricEelGem,

	SantaHat,
	CandyStick,
	ReindeerHeadband,
	StarLantern,

	BpSantaHat,
	BpCandyStick,
	BpReindeerHeadband,
	BpStarLantern,

	StingrayStone,
	StingrayGem,
	BatRayStone,
	BatRayGem,
	ElectricRayStone,
	ElectricRayGem,

	Moss,
	Fountain,
	UnicornHorn,
	SeaUrchin,
	CoconutTree,
	PropellerHat,

	BpMoss,
	BpFountain,
	BpUnicornHorn,
	BpSeaUrchin,
	BpCoconutTree,
	BpPropellerHat,

	WhiteWig,
	Quill,
	Hachimaki,
	MiniBubble,
	MusicNote,
	Battery,
	Bling,
	Ghost,
	SantaBeard,

	RankBMedal,
	RankAMedal,
	RankSMedal,
	RankSSMedal,
	RankSSSMedal,
	Score1Ring,
	Score2Pendant,
	Score3Belt,
	Score4Collar,
	Score5Crown,
	Kill1Ring,
	Kill2Pendant,
	Kill3Belt,
	Kill4Collar,
	Kill5Crown,

	BpWhiteWig,
	BpQuill,
	BpHachimaki,
	BpMiniBubble,
	BpMusicNote,
	BpBattery,
	BpBling,
	BpGhost,
	BpSantaBeard,

	BpRankBMedal,
	BpRankAMedal,
	BpRankSMedal,
	BpRankSSMedal,
	BpRankSSSMedal,
	BpScore1Ring,
	BpScore2Pendant,
	BpScore3Belt,
	BpScore4Collar,
	BpScore5Crown,
	BpKill1Ring,
	BpKill2Pendant,
	BpKill3Belt,
	BpKill4Collar,
	BpKill5Crown,


	PaperPlane,
	WhiteFlower,
	Baseball,
	SurgicalMask,
	Ink,
	Eyepatch,
	JugglingClub,
	LargeBubble,
	DizzyHalo,
	Apple,
	Potato,
	GhostPepperRed,
	GhostPepperGreen,
	Tuna,
	FlowerBouquet,
	BalloonGreen,
	BalloonYellow,
	BalloonRed,
	StageLight,

	BpPaperPlane,
	BpWhiteFlower,
	BpBaseball,
	BpSurgicalMask,
	BpInk,
	BpEyepatch,
	BpJugglingClub,
	BpLargeBubble,
	BpDizzyHalo,
	BpApple,
	BpPotato,
	BpGhostPepperRed,
	BpGhostPepperGreen,
	BpTuna,
	BpFlowerBouquet,
	BpBalloonGreen,
	BpBalloonYellow,
	BpBalloonRed,
	BpStageLight,

	BlackAfro,
	TrapperHat,
	RabbitEarsHeadband,
	AviatorSunglasses,
	ChefKnife,
	RedPacket,
	BeardOfFortune,
	HatOfFortune,

	BpBlackAfro,
	BpTrapperHat,
	BpRabbitEarsHeadband,
	BpAviatorSunglasses,
	BpChefKnife,
	BpRedPacket,
	BpBeardOfFortune,
	BpHatOfFortune,

	CrustaceanOrb,
	SakuraShrimpStone,
	SakuraShrimpGem,
	PistolShrimpStone,
	PistolShrimpGem,
	MantisShrimpStone,
	MantisShrimpGem,

	AmphibianOrb,
	FirebellyNewtStone,
	FirebellyNewtGem,
	FireSalamanderStone,
	FireSalamanderGem,
	AxolotlStone,
	AxolotlGem,

	Blush,
	Card,
	JokerCard,
	Maraca,
	LongGlasses,
	Book,
	Wheel,
	RainbowAfro,
	Balloons,

	BpBlush,
	BpCard,
	BpJokerCard,
	BpMaraca,
	BpLongGlasses,
	BpBook,
	BpWheel,
	BpRainbowAfro,
	BpBalloons,

	JackOLantern,
	JackOLanternJaw,
	BpJackOLantern,
	BpJackOLanternJaw,

	VampireSquidStone,
	VampireSquidGem,
	CuttlefishStone,
	CuttlefishGem,
	ColossalSquidStone,
	ColossalSquidGem,

	NeckRuffle,
	Wristband,
	UmbrellaRed,
	SquirtingBlood,
	IncognitoMask,
	BpNeckRuffle,
	BpWristband,
	BpUmbrellaRed,
	BpSquirtingBlood,
	BpIncognitoMask,

	BirdOrb,
	PenguinStone,
	PenguinGem,
	PirateBandana,
	PirateCaptainBicorne,
	BpPirateBandana,
	BpPirateCaptainBicorne,

	DragonHorns,
	BpDragonHorns,

	SwordfishOrb,
	SwordfishStone,
	SwordfishGem,
	MarlinStone,
	MarlinGem,
	SailfishStone,
	SailfishGem,

	SkewerStick,
	Crayon,
	DoodlesFish,
	DoodlesShark,
	DoodlesOctopus,
	DoodlesJellyfish,
	BaseballBat,
	CaptainCap,
	SailingShip,
	OperaGlasses,
	Staff,
	Katana,
	MasqueradeMask,
	BpSkewerStick,
	BpCrayon,
	BpDoodlesFish,
	BpDoodlesShark,
	BpDoodlesOctopus,
	BpDoodlesJellyfish,
	BpBaseballBat,
	BpCaptainCap,
	BpSailingShip,
	BpOperaGlasses,
	BpStaff,
	BpKatana,
	BpMasqueradeMask,

	BlueCrabStone,
	BlueCrabGem,
	KingCrabStone,
	KingCrabGem,
	SpiderCrabStone,
	SpiderCrabGem,

	KindergartenHat,
	String,
	Handcuffs,
	BrodieHelm,
	FootballHelmet,
	TopHat,
	Box,
	Scissors,
	Speaker,
	Headphone,
	Ukulele,
	BpKindergartenHat,
	BpString,
	BpHandcuffs,
	BpBrodieHelm,
	BpFootballHelmet,
	BpTopHat,
	BpBox,
	BpScissors,
	BpSpeaker,
	BpHeadphone,
	BpUkulele,

	SpiritOfStabfish,
	HopeOfStabfish,

	ReptileOrb,
	CrocodileStone,
	CrocodileGem,
	KronosaurusStone,
	KronosaurusGem,
	MosasaurusStone,
	MosasaurusGem,

	PlasticBag,
	BaseballGlove,
	Pencil,
	Egg,
	Toast,
	PotatoChips,
	Schoolbag,
	Clock,
	HydroTank,
	WheatStraw,
	Sombrero,
	ClownfishDoll,
	Bone,
	Skull,
	FishSkeleton,
	Tattoo,
	MenacingEffect,
	SpearGun,
	BunnyToy,
	TeddyBear,
	CarWheel,
	PocketWatch,
	Watch,
	Smartphone,
	Skateboard,
	BpPlasticBag,
	BpBaseballGlove,
	BpPencil,
	BpEgg,
	BpToast,
	BpPotatoChips,
	BpSchoolbag,
	BpClock,
	BpHydroTank,
	BpWheatStraw,
	BpSombrero,
	BpClownfishDoll,
	BpBone,
	BpSkull,
	BpFishSkeleton,
	BpTattoo,
	BpMenacingEffect,
	BpSpearGun,
	BpBunnyToy,
	BpTeddyBear,
	BpCarWheel,
	BpPocketWatch,
	BpWatch,
	BpSmartphone,
	BpSkateboard,

	OarfishOrb,
	KoiStone,
	KoiGem,
	OarfishStone,
	OarfishGem,
	DragonStone,
	DragonGem,

	CandleFire,
	Boba,
	PunkHair,
	Fez,
	Fan,
	FeatheredFan,
	Accordion,
	Ribbon,
	Present,
	TobaccoPipe,
	Smoke,
	Paddle,
	Brush,
	IV,
	Syringe,
	SmallExplosion,
	Rifle,
	Pistol,
	Rocket,
	Spaceship,
	Bazooka,
	FuryEyepatch,
	OniMouthpiece,

	BpCandleFire,
	BpBoba,
	BpPunkHair,
	BpFez,
	BpFan,
	BpFeatheredFan,
	BpAccordion,
	BpRibbon,
	BpPresent,
	BpTobaccoPipe,
	BpSmoke,
	BpPaddle,
	BpBrush,
	BpIV,
	BpSyringe,
	BpSmallExplosion,
	BpRifle,
	BpPistol,
	BpRocket,
	BpSpaceship,
	BpBazooka,
	BpFuryEyepatch,
	BpOniMouthpiece,

	SantaBeardBig,
	Diamond,
	BatWing,
	CuteWing,
	ExpandedWing,
	Halo,
	HaloRing,
	CloudHalo,
	RainbowRay,
	ElectricNode,
	LightningScar,
	ElectricGuitarRed,
	ElectricGuitarPurple,

	BpSantaBeardBig,
	BpDiamond,
	BpBatWing,
	BpCuteWing,
	BpExpandedWing,
	BpHalo,
	BpHaloRing,
	BpCloudHalo,
	BpRainbowRay,
	BpElectricNode,
	BpLightningScar,
	BpElectricGuitarRed,
	BpElectricGuitarPurple,
}


export type ItemType = 'currency' | 'material' | 'deco' | 'blueprint' | 'skin' | 'pet';

export interface ItemInfo {
	code: ItemCode,
	type: ItemType,
	tradable: boolean,
	name: string,
	desc: string,
	rarity: Rarity,
	decoLimit?: number,
}

export const itemInfos: ItemInfo[] = [];


let code: ItemCode;

code = ItemCode.None;
itemInfos[code] = {
	code,
	type: 'currency',
	name: 'None',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.Money;
itemInfos[code] = {
	code,
	type: 'currency',
	name: 'Gold',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.Gem;
itemInfos[code] = {
	code,
	type: 'currency',
	name: 'Black Pearl',
	tradable: false,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.GoldenIdol;
itemInfos[code] = {
	code,
	type: 'currency',
	name: 'Golden Idol',
	tradable: false,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.PirateToken;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Pirate Token',
	tradable: false,
	desc: 'Silver tokens dropped by the pirate penguins, useful for later event updates.',
	rarity: Rarity.Rare,
};

// Rewards

code = ItemCode.LevelCrystal;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Level Crystal',
	tradable: false,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.RankBRock;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Rank B Plate',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.RankARock;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Rank A Plate',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.RankSRock;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Rank S Plate',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.RankSSRock;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Rank SS Plate',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.RankSSSRock;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Rank SSS Plate',
	tradable: false,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.Score1Rock;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Adequate Crystal',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.Score2Rock;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Pro Crystal',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.Score3Rock;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Expert Crystal',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.Score4Rock;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Master Crystal',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.Score5Rock;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Grandmaster Crystal',
	tradable: false,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.Kill1Rock;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Rage Crystal',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.Kill2Rock;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Blood Crystal',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.Kill3Rock;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Death Crystal',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.Kill4Rock;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Carnage Crystal',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.Kill5Rock;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Extinction Crystal',
	tradable: false,
	desc: '',
	rarity: Rarity.Legendary,
};


// Ore

code = ItemCode.SpinelOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Spinel Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.GarnetOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Garnet Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.RubyOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Ruby Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};


code = ItemCode.CopperOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Copper Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.CitrineOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Citrine Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.GoldOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Gold Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.PeridotOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Peridot Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.TurquoiseOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Turquoise Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.EmeraldOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Emerald Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.LapisLazuliOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Lapis Lazuli Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.AquamarineOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Aquamarine Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.SapphireOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Sapphire Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.QuartzOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Quartz Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.AmethystOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Amethyst Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.DiamondOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Diamond Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.IronOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Iron Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.SilverOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Silver Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.MythrilOre;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Mythril Ore',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

// Fish Orbs

code = ItemCode.SharkOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Shark Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.DolphinOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Dolphin Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.WhaleOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Whale Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.PufferfishOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Puffer Fish Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.NarwhalOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Narwhal Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.CarpOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Carp Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.TurtleOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Turtle Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.CephalopodOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Cephalopod Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.MythicalOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Mythical Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.RayOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Ray Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.EelOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Eel Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.KnifefishOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Knifefish Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.CrustaceanOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Crustacean Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.AmphibianOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Amphibian Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BirdOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Bird Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.SwordfishOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Swordfish Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.ReptileOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Reptile Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.OarfishOrb;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Oarfish Orb',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};



// Fish Stones

code = ItemCode.BlueSharkStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Blue Shark Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.BlueSharkGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Blue Shark Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.GreatWhiteSharkStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Great White Shark Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.GreatWhiteSharkGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Great White Shark Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.MegalodonStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Megalodon Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.MegalodonGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Megalodon Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};


code = ItemCode.HammerheadStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Hammerhead Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.HammerheadGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Hammerhead Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.HammeredHeadStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'HammeredHead Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.HammeredHeadGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'HammeredHead Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};

code = ItemCode.WhammyHeadStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Whammyhead Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.WhammyHeadGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Whammyhead Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};


code = ItemCode.KillerWhaleStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Killer Whale Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.KillerWhaleGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Killer Whale Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.SerialKillerWhaleStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Serial Killer Whale Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.SerialKillerWhaleGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Serial Killer Whale Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.PsychoKillerWhaleStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Psycho Killer Whale Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.PsychoKillerWhaleGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Psycho Killer Whale Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.BottlenoseDolphinStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Bottlenose Dolphin Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.BottlenoseDolphinGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Bottlenose Dolphin Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.CommonDolphinStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Common Dolphin Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.CommonDolphinGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Common Dolphin Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.SpinnerDolphinStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Spinner Dolphin Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.SpinnerDolphinGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Spinner Dolphin Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};


code = ItemCode.PufferfishStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Puffer Fish Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.PufferfishGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Puffer Fish Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.PorcupinefishStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Porcupine Fish Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.PorcupinefishGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Porcupine Fish Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.OnifuguStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Onifugu Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.OnifuguGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Onifugu Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.NarwhalStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Narwhal Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.NarwhalGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Narwhal Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.BelugaStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Beluga Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.BelugaGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Beluga Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.ArchBelugaStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'ArchBeluga Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.ArchBelugaGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'ArchBeluga Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.GoldfishStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Goldfish Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.GoldfishGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Goldfish Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.GoldenfishStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Goldenfish Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.GoldenfishGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Goldenfish Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.DiamondfishStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Diamond Fish Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.DiamondfishGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Diamond Fish Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.GreenSeaTurtleStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Green Sea Turtle Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.GreenSeaTurtleGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Green Sea Turtle Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.LeatherbackStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Leatherback Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.LeatherbackGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Leatherback Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.AlligatorSnappingTurtleStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Alligator Snapping Turtle Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.AlligatorSnappingTurtleGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Alligator Snapping Turtle Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.HumpbackWhaleStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Humpback Whale Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.HumpbackWhaleGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Humpback Whale Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.BlueWhaleStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Blue Whale Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.BlueWhaleGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Blue Whale Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.JomamaStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Jomama Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.JomamaGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Jomama Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.DumboOctopusStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Dumbo Octopus Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.DumboOctopusGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Dumbo Octopus Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.TakoStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Tako Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.TakoGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Tako Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.KrakenStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Kraken Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.KrakenGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Kraken Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.MantaRayStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Manta Ray Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.MantaRayGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Manta Ray Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.MantraRayStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Mantra Ray Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.MantraRayGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Mantra Ray Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.BlueRayStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Blue Ray Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.BlueRayGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Blue Ray Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.MorayEelStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Moray Eel Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.MorayEelGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Moray Eel Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.GulperEelStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Gulper Eel Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.GulperEelGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Gulper Eel Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.ElectricEelStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Electric Eel Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.ElectricEelGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Electric Eel Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.StingrayStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Stingray Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.StingrayGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Stingray Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.BatRayStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Bat Ray Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.BatRayGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Bat Ray Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.ElectricRayStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Electric Ray Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.ElectricRayGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Electric Ray Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.SakuraShrimpStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Sakura Shrimp Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.SakuraShrimpGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Sakura Shrimp Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.PistolShrimpStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Pistol Shrimp Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.PistolShrimpGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Pistol Shrimp Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.MantisShrimpStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Mantis Shrimp Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.MantisShrimpGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Mantis Shrimp Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.FirebellyNewtStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Fire-belly Newt Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.FirebellyNewtGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Fire-belly Newt Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.FireSalamanderStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Fire Salamander Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.FireSalamanderGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Fire Salamander Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.AxolotlStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Axolotl Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.AxolotlGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Axolotl Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.VampireSquidStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Vampire Squid Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.VampireSquidGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Vampire Squid Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.CuttlefishStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Cuttlefish Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.CuttlefishGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Cuttlefish Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.ColossalSquidStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Colossal Squid Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.ColossalSquidGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Colossal Squid Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.PenguinStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Penguin Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.PenguinGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Penguin Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.SwordfishStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Swordfish Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.SwordfishGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Swordfish Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.MarlinStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Marlin Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.MarlinGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Marlin Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.SailfishStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Sailfish Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.SailfishGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Sailfish Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.BlueCrabStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Blue Crab Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.BlueCrabGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Blue Crab Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.KingCrabStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'King Crab Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.KingCrabGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'King Crab Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.SpiderCrabStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Spider Crab Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.SpiderCrabGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Spider Crab Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.CrocodileStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Crocodile Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.CrocodileGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Crocodile Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.KronosaurusStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Kronosaurus Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.KronosaurusGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Kronosaurus Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.MosasaurusStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Mosasaurus Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.MosasaurusGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Mosasaurus Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.KoiStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Koi Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.KoiGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Koi Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.OarfishStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Oarfish Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.OarfishGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Oarfish Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};
code = ItemCode.DragonStone;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Dragon Stone',
	rarity: Rarity.Common,
	tradable: true,
	desc: '',
};
code = ItemCode.DragonGem;
itemInfos[code] = {
	code,
	type: 'material',
	name: 'Dragon Gem',
	rarity: Rarity.Legendary,
	tradable: true,
	desc: '',
};


code = ItemCode.BeardOfFortune;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Beard Of Fortune',
	tradable: true,
	desc: 'Give wearer 20% additional gold drop during Lunar New Year Event',
	rarity: Rarity.Epic,
};
code = ItemCode.BpBeardOfFortune;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Beard Of Fortune Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.HatOfFortune;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Hat Of Fortune',
	tradable: true,
	desc: 'Give wearer 30% additional gold drop during Lunar New Year Event',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpHatOfFortune;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Hat Of Fortune Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.HopeOfStabfish;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Hope Of Stabfish',
	tradable: false,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.SpiritOfStabfish;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Spirit Of Stabfish',
	tradable: false,
	desc: '',
	rarity: Rarity.Legendary,
};






// Decorations

code = ItemCode.ExpandedWing;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Expanded Wing',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpExpandedWing;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Expanded Wing Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.SantaBeardBig;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Big Santa Beard',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpSantaBeardBig;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Big Santa Beard Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.HaloRing;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Halo Ring',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpHaloRing;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Halo Ring Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.LightningScar;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Lightning Scar',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpLightningScar;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Lightning Scar Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.CloudHalo;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Cloud Halo',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpCloudHalo;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Cloud Halo Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.CuteWing;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Cute Wing',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpCuteWing;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Cute Wing Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.Halo;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Halo',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpHalo;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Halo Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.ElectricNode;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Electric Node',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpElectricNode;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Electric Node Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.RainbowRay;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Rainbow Ray',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpRainbowRay;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Rainbow Ray Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.DoodlesJellyfish;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Jellyfish Doodle',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpDoodlesJellyfish;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Jellyfish Doodle Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.BatWing;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Bat Wing',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpBatWing;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Bat Wing Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.Diamond;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Diamond',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpDiamond;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Diamond Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.JackOLanternJaw;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Jack-O\'-Lantern\'s Jaw',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpJackOLanternJaw;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Jack-O\'-Lantern\'s Jaw Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.JackOLantern;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Jack-O‘-Lantern',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpJackOLantern;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Jack-O‘-Lantern Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.RankBMedal;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Rank B Medal',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpRankBMedal;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Rank B Medal Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.RankAMedal;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Rank A Medal',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpRankAMedal;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Rank A Medal Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.RankSMedal;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Rank S Medal',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpRankSMedal;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Rank S Medal Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.RankSSMedal;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Rank SS Medal',
	tradable: false,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpRankSSMedal;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Rank SS Medal Blueprint',
	tradable: false,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.RankSSSMedal;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Rank SSS Medal',
	tradable: false,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpRankSSSMedal;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Rank SSS Medal Blueprint',
	tradable: false,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.Score1Ring;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Adequate Ring',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpScore1Ring;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Adequate Ring Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.Score2Pendant;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Pro Pendant',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpScore2Pendant;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Pro Pendant Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Score3Belt;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Expert Belt',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpScore3Belt;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Expert Belt Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Score4Collar;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Master Collar',
	tradable: false,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpScore4Collar;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Master Collar Blueprint',
	tradable: false,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Score5Crown;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Grandmaster Crown',
	tradable: false,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpScore5Crown;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Grandmaster Crown Blueprint',
	tradable: false,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.Kill1Ring;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Rage Ring',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpKill1Ring;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Rage Ring Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.Kill2Pendant;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Blood Pendant',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpKill2Pendant;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Blood Pendant Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Kill3Belt;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Death Belt',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpKill3Belt;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Death Belt Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Kill4Collar;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Carnage Collar',
	tradable: false,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpKill4Collar;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Carnage Collar Blueprint',
	tradable: false,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Kill5Crown;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Extinction Crown',
	tradable: false,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpKill5Crown;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Extinction Crown Blueprint',
	tradable: false,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.SantaHat;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Santa Hat',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpSantaHat;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Santa Hat Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.CandyStick;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Candy Stick',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpCandyStick;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Candy Stick Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.SantaBeard;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Santa Beard',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpSantaBeard;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Santa Beard Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.ReindeerHeadband;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Reindeer Headband',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpReindeerHeadband;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Reindeer Headband Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.StarLantern;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Star Lantern',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpStarLantern;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Star Lantern Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.RedPacket;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Red Packet',
	tradable: true,
	desc: 'Give wearer 50% additional gold drop during Lunar New Year Event',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpRedPacket;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Red Packet Blueprint',
	tradable: true,
	desc: 'Give wearer 50% additional gold drop during Lunar New Year Event',
	rarity: Rarity.Uncommon,
};

code = ItemCode.PirateBandana;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Pirate Bandana',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpPirateBandana;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Pirate Bandana Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.PirateCaptainBicorne;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Pirate Captain Bicorne',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpPirateCaptainBicorne;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Pirate Captain Bicorne Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.Shield;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Shield',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpShield;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Shield Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Sword;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Sword',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpSword;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Sword Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Saber;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Saber',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpSaber;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Saber Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.SharkSaberYellow;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Yellow Shark Saber',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpSharkSaberYellow;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Yellow Shark Saber Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.SharkSaberRed;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Red Shark Saber',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpSharkSaberRed;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Red Shark Saber Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.SharkSaberPurple;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Purple Shark Saber',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpSharkSaberPurple;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Purple Shark Saber Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.SharkSaberBlue;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Blue Shark Saber',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpSharkSaberBlue;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Blue Shark Saber Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.DolphinSwordPurple;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Purple Dolphin Sword',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpDolphinSwordPurple;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Purple Dolphin Sword Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.DolphinSwordGreen;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Green Dolphin Sword',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpDolphinSwordGreen;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Green Dolphin Sword Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.DolphinSwordTeal;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Teal Dolphin Sword',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpDolphinSwordTeal;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Teal Dolphin Sword Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.DolphinSwordBlack;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Black Dolphin Sword',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpDolphinSwordBlack;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Black Dolphin Sword Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.Quill;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Quill',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpQuill;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Quill Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.UnicornHorn;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Unicorn Horn',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpUnicornHorn;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Unicorn Horn Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.SeaUrchin;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Sea Urchin',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpSeaUrchin;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Sea Urchin Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.Moss;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Moss',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpMoss;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Moss Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.WhiteWig;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'White Wig',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpWhiteWig;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'White Wig Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.PaperPlane;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Paper Plane',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpPaperPlane;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Paper Plane Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.Blush;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Blush',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpBlush;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Blush Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.SkewerStick;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Skewer Stick',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpSkewerStick;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Skewer Stick Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.KindergartenHat;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Kindergarten Hat',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpKindergartenHat;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Kindergarten Hat Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.PlasticBag;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Plastic Bag',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpPlasticBag;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Plastic Bag Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.BlueCap;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Blue Cap',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpBlueCap;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Blue Cap Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.Fountain;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Fountain',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpFountain;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Fountain Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.Hachimaki;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Hachimaki',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpHachimaki;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Hachimaki Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.MiniBubble;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Mini Bubble',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpMiniBubble;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Mini Bubble Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.BeachBall;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Beach Ball',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpBeachBall;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Beach Ball Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.Bandaid;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Bandaid',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpBandaid;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Bandaid Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.BumpOnHead;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Bump On Head',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};
code = ItemCode.BpBumpOnHead;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Bump On Head Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Common,
};

code = ItemCode.Hoop;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Hoop',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpHoop;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Hoop Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.BandaidX;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Bandaid X',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpBandaidX;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Bandaid X Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.CoconutTree;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Coconut Tree',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpCoconutTree;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Coconut Tree Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.SurgicalMask;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Surgical Mask',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpSurgicalMask;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Surgical Mask Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Battery;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Battery',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpBattery;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Battery Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Card;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Card',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpCard;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Card Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.NeckRuffle;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Neck Ruffle',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpNeckRuffle;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Neck Ruffle Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.DoodlesShark;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Shark Doodle',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpDoodlesShark;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Shark Doodle Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.DoodlesOctopus;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Octopus Doodle',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpDoodlesOctopus;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Octopus Doodle Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.String;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'String',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpString;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'String Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.BaseballGlove;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Baseball Glove',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpBaseballGlove;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Baseball Glove Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Boba;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Boba',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpBoba;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Boba Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.PunkHair;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Punk Hair',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpPunkHair;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Punk Hair Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.CandleFire;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Candle Fire',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpCandleFire;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Candle Fire Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Bungalow;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Bungalow',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpBungalow;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Bungalow Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.MusicNote;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Music Note',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpMusicNote;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Music Note Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Wristband;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Wristband',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpWristband;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Wristband Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.DoodlesFish;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Fish Doodle',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpDoodlesFish;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Fish Doodle Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Pencil;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Pencil',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpPencil;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Pencil Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Fan;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Fan',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpFan;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Fan Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Fez;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Fez',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpFez;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Fez Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Baseball;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Baseball',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpBaseball;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Baseball Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Glasses;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Glasses',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpGlasses;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Glasses Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Crayon;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Crayon',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpCrayon;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Crayon Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Egg;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Egg',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpEgg;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Egg Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.WhiteFlower;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'White Flower',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpWhiteFlower;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'White Flower Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Ink;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Ink',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};
code = ItemCode.BpInk;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Ink Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Uncommon,
};

code = ItemCode.Scar1;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Scar',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpScar1;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Scar Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.GentlemanMustache;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Gentleman Mustache',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpGentlemanMustache;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Gentleman Mustache Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.JugglingClub;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Juggling Club',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpJugglingClub;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Juggling Club Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.LargeBubble;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Large Bubble',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpLargeBubble;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Large Bubble Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Bling;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Bling',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpBling;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Bling Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.PropellerHat;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Propeller Hat',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpPropellerHat;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Propeller Hat Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Apple;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Apple',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpApple;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Apple Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Ghost;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Ghost',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpGhost;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Ghost Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.JokerCard;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Joker Card',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpJokerCard;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Joker Card Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Maraca;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Maraca',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpMaraca;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Maraca Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.UmbrellaRed;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Red Umbrella',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpUmbrellaRed;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Red Umbrella Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.BaseballBat;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Baseball Bat',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpBaseballBat;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Baseball Bat Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.CaptainCap;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Captain Cap',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpCaptainCap;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Captain Cap Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.TopHat;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Top Hat',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpTopHat;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Top Hat Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.BrodieHelm;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Brodie Helm',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpBrodieHelm;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Brodie Helm Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Handcuffs;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Handcuffs',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpHandcuffs;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Handcuffs Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Toast;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Toast',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpToast;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Toast Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Clock;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Clock',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpClock;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Clock Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.PotatoChips;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Potato Chips',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpPotatoChips;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Potato Chips Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.IV;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Intravenous Pole',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpIV;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Intravenous Pole Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Paddle;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Paddle',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpPaddle;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Paddle Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.TobaccoPipe;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Tobacco Pipe',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpTobaccoPipe;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Tobacco Pipe Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.TrapperHat;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Trapper Hat',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpTrapperHat;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Trapper Hat Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.DizzyHalo;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Dizzy Halo',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpDizzyHalo;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Dizzy Halo Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.RedNose;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Red Nose',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpRedNose;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Red Nose Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Eyepatch;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Eyepatch',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpEyepatch;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Eyepatch Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.GhostPepperRed;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Red Ghost Pepper',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpGhostPepperRed;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Red Ghost Pepper Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.FootballHelmet;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Football Helmet',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpFootballHelmet;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Football Helmet Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Schoolbag;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Schoolbag',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpSchoolbag;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Schoolbag Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.HydroTank;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Hydro Tank',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpHydroTank;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Hydro Tank Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Bone;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Bone',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpBone;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Bone Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.FeatheredFan;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Feathered Fan',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpFeatheredFan;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Feathered Fan Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Brush;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Brush',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpBrush;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Brush Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Smoke;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Smoke',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpSmoke;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Smoke Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.LongGlasses;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Long Glasses',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpLongGlasses;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Long Glasses Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.AmericanFootball;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'American Football',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpAmericanFootball;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'American Football Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Potato;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Potato',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpPotato;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Potato Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.GhostPepperGreen;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Green Ghost Pepper',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpGhostPepperGreen;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Green Ghost Pepper Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Book;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Book',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpBook;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Book Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Box;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Box',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpBox;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Box Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Sombrero;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Sombrero',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpSombrero;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Sombrero Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Accordion;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Accordion',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpAccordion;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Accordion Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Ribbon;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Ribbon',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpRibbon;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Ribbon Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Wheel;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Wheel',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpWheel;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Wheel Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.BlackAfro;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Black Afro',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpBlackAfro;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Black Afro Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.ClownfishDoll;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Clownfish Doll',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpClownfishDoll;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Clownfish Doll Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.Present;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Present',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpPresent;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Present Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.SpiralGlasses;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Spiral Glasses',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpSpiralGlasses;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Spiral Glasses Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.RabbitEarsHeadband;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Rabbit Ears Headband',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpRabbitEarsHeadband;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Rabbit Ears Headband Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.WheatStraw;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Wheat Straw',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};
code = ItemCode.BpWheatStraw;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Wheat Straw Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Rare,
};

code = ItemCode.ScarX;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Scar X',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpScarX;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Scar X Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Warpaint1;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Warpaint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpWarpaint1;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Warpaint Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Tuna;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Tuna',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpTuna;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Tuna Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.BikerSunglasses;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Biker Sunglasses',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpBikerSunglasses;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Biker Sunglasses Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.BalloonRed;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Red Balloon',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpBalloonRed;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Red Balloon Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.ChefKnife;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Chef Knife',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpChefKnife;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Chef Knife Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.StageLight;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Stage Light',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpStageLight;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Stage Light Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.RainbowAfro;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Rainbow Afro',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpRainbowAfro;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Rainbow Afro Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.SquirtingBlood;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Squirting Blood',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpSquirtingBlood;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Squirting Blood Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.IncognitoMask;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Incognito Mask',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpIncognitoMask;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Incognito Mask Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Staff;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Staff',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpStaff;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Staff Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.SailingShip;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Sailing Ship',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpSailingShip;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Sailing Ship Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Scissors;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Scissors',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpScissors;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Scissors Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Speaker;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Speaker',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpSpeaker;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Speaker Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Ukulele;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Ukulele',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpUkulele;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Ukulele Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.TeddyBear;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Teddy Bear',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpTeddyBear;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Teddy Bear Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.SpearGun;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Spear Gun',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpSpearGun;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Spear Gun Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Tattoo;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Tattoo',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpTattoo;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Tattoo Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Syringe;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Syringe',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpSyringe;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Syringe Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.SmallExplosion;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Small Explosion',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpSmallExplosion;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Small Explosion Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.DragonHorns;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Dragon Horns',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpDragonHorns;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Dragon Horns Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Warpaint2;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Warpaint 2',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpWarpaint2;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Warpaint 2 Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Monocle;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Monocle',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpMonocle;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Monocle Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.BalloonYellow;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Yellow Balloon',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpBalloonYellow;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Yellow Balloon Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.OperaGlasses;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Opera Glasses',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpOperaGlasses;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Opera Glasses Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Katana;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Katana',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpKatana;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Katana Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Headphone;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Headphone',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpHeadphone;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Headphone Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.BunnyToy;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Bunny Toy',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpBunnyToy;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Bunny Toy Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Smartphone;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Smartphone',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpSmartphone;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Smartphone Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.MenacingEffect;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Menacing Effect',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpMenacingEffect;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Menacing Effect Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Rifle;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Rifle',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpRifle;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Rifle Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Warpaint4;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Warpaint 4',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpWarpaint4;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Warpaint 4 Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.FlowerBouquet;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Flower Bouquet',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpFlowerBouquet;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Flower Bouquet Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.BalloonGreen;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Green Balloon',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpBalloonGreen;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Green Balloon Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.CarWheel;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Car Wheel',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpCarWheel;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Car Wheel Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.PocketWatch;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Pocket Watch',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpPocketWatch;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Pocket Watch Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.FishSkeleton;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Fish Skeleton',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpFishSkeleton;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Fish Skeleton Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Pistol;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Pistol',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpPistol;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Pistol Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.AviatorSunglasses;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Aviator Sunglasses',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpAviatorSunglasses;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Aviator Sunglasses Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Watch;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Watch',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpWatch;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Watch Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Skull;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Skull',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpSkull;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Skull Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Rocket;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Rocket',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};
code = ItemCode.BpRocket;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Rocket Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Epic,
};

code = ItemCode.Balloons;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Balloons',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpBalloons;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Balloons Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.MasqueradeMask;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Masquerade Mask',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpMasqueradeMask;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Masquerade Mask Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.ElectricGuitarRed;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Red Electric Guitar',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpElectricGuitarRed;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Red Electric Guitar Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.Skateboard;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Skateboard',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpSkateboard;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Skateboard Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.OniMouthpiece;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Oni Mouthpiece',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpOniMouthpiece;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Oni Mouthpiece Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.ElectricGuitarPurple;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Purple Electric Guitar',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpElectricGuitarPurple;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Purple Electric Guitar Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.Spaceship;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Spaceship',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpSpaceship;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Spaceship Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.Bazooka;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Bazooka',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpBazooka;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Bazooka Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};

code = ItemCode.FuryEyepatch;
itemInfos[code] = {
	code,
	type: 'deco',
	name: 'Fury Eyepatch',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
code = ItemCode.BpFuryEyepatch;
itemInfos[code] = {
	code,
	type: 'blueprint',
	name: 'Fury Eyepatch Blueprint',
	tradable: true,
	desc: '',
	rarity: Rarity.Legendary,
};
