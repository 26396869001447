import { nonReact } from '@/util/nonReact';
import { Pool } from '@/util/pool';
import { MovieClip } from 'pixi-animate';
import { Sprite, Texture } from 'pixi.js';
import { getAllTimedChildren } from './helper';

export interface ParticleData {
	u: number,
	v: number,
	texture: Texture,
}

const particles: ParticleData[] = nonReact([]);
const particlePools: Sprite[][] = nonReact([]);

export enum Particle {
	FoodAlgae,
	FoodPrawn,
	FoodFillet,
	FoodSalmon,

	Hit,
	Cut,
	Boom,
	Star,
	Plus,
	Circle,

	TeamCircle,
	Shadow,

	MiniBubble,

	Pointer,
	Infinity,
	MissileIndicator,
	Target,

	MiningSparkle,
	LegendaryItemBling,

	BlueprintBottle,
	CrossGlow,

	JobuffRed,
	JobuffSilver,
	JobuffYellow,
	RainbowGlare,
	BigBling,

	Confetti,
}

export function registerParticles(symbol: MovieClip) {

	const list = particles;
	const pools = particlePools;
	const timeline = (symbol as any)._timelines;
	const children = getAllTimedChildren(symbol);
	for (let i = 0; i < children.length; i++) {
		const child = children[i];
		if (i === 0) { child.x = child.y = 0; }
		const pos = timeline[i]?._currentProps || { x: 0, y: 0 };
		const rect = child.getLocalBounds();
		const u = -(child.x + pos.x || 0) / rect.width;
		const v = -(child.y + pos.y || 0) / rect.height;
		const texture = (child as Sprite).texture;
		list[i] = { u, v, texture };
		pools[i] = [];
	}
	// console.log(symbol);
	symbol.destroy();
}
export function getParticle(index: Particle) {

	const pool = particlePools[index];
	const old = pool.shift();
	if (old) { return old; }

	const data = particles[index];
	const symbol = Sprite.from(data.texture);
	symbol.anchor.set(data.u, data.v);

	return symbol;
}
export function disposeParticle(index: Particle, particle: Sprite) {
	particlePools[index].push(particle);
}
