import { Dictionary } from '@/util/dictionary';
import { DecoSet } from './decorativeInfos';
import { PlayerType } from './enums';
import { FishType } from './fishInfos';
import { HazardType } from './hazardInfos';
import { ItemCode } from './itemInfos';
import { SkinGroup } from './skinInfos';
import { SpearType } from './spearInfos';

export enum UnlockChallengeType {
	Accumulate,
	OneGame,
	Contribute,
	UnlockFish,
	Level,
	Craft,
	Decorate,
	Mission,
	Gacha,
	Purchase,
	Login,
	NumUnlock,
	NumNewCraft,
	NumCraft,
	NumDeco,
	NumSkins,
	NumSummons,
	LinkEmail,
	EmailVerify,
	LinkPoq,
	UnlockSf1,
	PlaySf1,
	LoginSf1,
	Boss,
}
export enum QuestType {
	Main,
	Weekly,
	TimeLimited,
}

export interface UnlockChallenge {
	type: UnlockChallengeType,
	goal: number,

	unlockId: number,
	isMaster?: boolean,
	step: number,
	index: number,

	description: string,

	material?: ItemCode,
	questType?: QuestType,
}

export type UnlockSteps = UnlockChallenge[][];

export interface UnlockChallengeProgress {
	completed: boolean,
	current: number,
}
export interface QuestProgress {
	currentStep: number,
	progresses: Dictionary<number>,
}
export interface Quest {
	title: string,
	type: QuestType,
	code: number,
	startTime?: number,
	endTime?: number,
	steps: QuestStep[],
	isSf1?: boolean,
}
export interface ItemCodeAmountPair {
	itemCode: ItemCode,
	amount: number,
}
export interface QuestStep {
	rewards: ItemCodeAmountPair[],
	challenges: UnlockChallenge[],
}


export interface UnlockAction {
	sessionId: string,
	uid: string,
	fishTypes: FishType[],
	currentLife: number,
	finalize?: boolean,
	skin?: SkinGroup,
	serverName?: string,
	ip?: string,
	name?: string,
	decoSet?: string,
	spearType?: SpearType,

	amgdPlace?: number,
	numAmgdContenders?: number,
	amgdTimeUsed?: number,

	explorerTimeUsed?: number,

	// apply to others
	mined: number,
	stabbed: number,
	killed: number;

	rescuedT: number,
	rescuedE: number,

	items: Dictionary<number>, // itemCode: 2
	publicChest?: Dictionary<number>, // itemCode: 2
	templeChest?: Dictionary<number>, // itemCode: 2
	rewards?: Dictionary<number>, // itemCode: 2

	score: number,
	money: number,
	scoreSpent: number,
	foodScore?: number,
	spearsBought?: number, // num spear bought

	scores: number[],
	kills: number[],
	survivalRecord: number,
	sstreak: number,
	kstreak: number,
	nmsRecord?: number,
	nmsKills?: number,
	nmsRels?: number,

	action: UnlockActionFrame,

	chat?: number,
	dBless?: boolean,

	// ONE Game Accumulate
	serialkw?: number,
	psychokw?: number,
	goldenf?: number,
	eleceel?: number,
	mantisS?: number,
	marlinPa?: number,
	marlinWt?: number,
	marlinCs?: number,
	marlinCb?: number,

	zf?: number,
	ak1?: number,
	ak2?: number,
	ak3?: number,
}
export interface UnlockActionFrame {
	explorerTimeUsed?: number,
	mined?: number,
	stabbed?: number,
	killed?: number,
	rescuedT?: number,
	rescuedE?: number,
	items?: Dictionary<number>, // itemCode: 2
	publicChest?: number,
	templeChest?: number,
	foodScore?: number,
	score?: number,
	money?: number,
	spearsBought?: number,
	sstreak?: number,
	kstreak?: number,
	rgene?: number,

	hammerh?: number,
	whammyh?: number,
	bottlenoseD?: number,
	spinnerD?: number, // record
	porc?: number,
	onifugu?: number, // 1
	diamondF?: number, // 1
	leatherback?: number, // record
	snappingT?: number,
	blueW?: number, // record
	dumboO?: number, // 1
	blueray?: number, // 1
	moreel?: number, // record
	gulpeel?: number, // 1
	sray?: number,
	batray?: number,
	elecray?: number,
	pistolS?: number,
	fireN?: number,
	fireS?: number,
	axolotl?: number,
	vampS?: number, // record
	wZelly?: number, // 1
	wIron?: number, // 1
	whirSK?: number,
	sailF?: number,
	blueC?: number,
	kingC?: number,
	spiderC?: number,
	croc?: number,
	kron?: number,
	mosa?: number,
	oar?: number,

	xmas21Bear?: number, // 1
	xmas21Enemy?: number,
	xmas21Team?: number, // 1
	xmas21bpStar?: number, // record
	xmas21RedN?: number,
	xmas21Rudolph?: number, // record
	xmas22Spear?: number, // 1
	xmas22Release?: number,
	xmas22AirDrop?: number, // 1

	cny23gxfc?: number, // 1
	cny23red?: number, // 1

	zkk?: number,
	zki?: number,

	ahoy?: number,
	peng?: number,
	macg?: number,
}

export function getEmptyUnlockAction(): UnlockAction {
	return {
		currentLife: 0,
		sessionId: '',
		uid: '',
		fishTypes: [0, 0, 0],

		mined: 0,
		stabbed: 0,
		killed: 0,

		rescuedT: 0,
		rescuedE: 0,

		items: {}, // itemCode: 2

		score: 0,
		money: 0,
		scoreSpent: 0,

		scores: [],
		kills: [],
		survivalRecord: 0,
		sstreak: 0,
		kstreak: 0,

		action: {},
	};
}

