import { generateDateString } from '@/util/date';
import { Dictionary } from '@/util/dictionary';
import random from '@/util/random';
import { DecoSet } from './decorativeInfos';
import { FishType } from './fishInfos';
import { ItemCode } from './itemInfos';
import { ServerInfo } from './serverInfos';
import { SkinGroup } from './skinInfos';
import { QuestProgress, UnlockChallengeProgress } from './unlockModels';
import { ShopLimitedIds } from './limitedShop';

export interface StabfishAccountData {
	version: number,
	name: string,
	exp: number,
	lvl: number,
	teamId: string,
	uid: string,
	unlockedFishes: Dictionary<boolean>,
	unlockProgress: Dictionary<Dictionary<UnlockChallengeProgress[]>>,
	decoSets: Dictionary<string>,
	nonAnonymous: boolean,
	banned: boolean,
	nameBanned?: number,
	poq: PoqIndentity | null,
	crazyGames?: string | null,
	timeLimitedYear?: number,
	mainQuestProgress: Dictionary<QuestProgress>,
	weeklyQuestProgress: Dictionary<QuestProgress>,
	timeLimitedQuestProgress: Dictionary<QuestProgress>,
	blueprintQuestProgress: Dictionary<Dictionary<number>>,
	weeklyQuestTime: number,

	record?: StabfishDataRecord,

	lastServer: string | null,
	lastPlay: number,

	lastSessionReport: {
		sessionId: string,
		newExp: number,
		newLvl: number,
		hitLvlCap: boolean,
		progress: string[],

		mined: number,
		money: number,
		score: number,
		kills: number,
		killstreaks: number,
		stabs: number,
		stabstreaks: number,
		nmsStage?: number,

		oldRecord?: {
			mined: number,
			money: number,
			score: number,
			kills: number,
			killstreaks: number,
			stabs: number,
			stabstreaks: number,
			nmsStage?: number,
		} | null;

		loot: Dictionary<number>,
		rewards: Dictionary<number>,

	} | null,

	bossRecords?: Dictionary<Dictionary<BossRecord>>,
	gladRecords?: {
		pks: number,
		totalPks: number,
		pkp: number,
		totalPkp: number,
		wins: number,
		antiDupe?: Dictionary<number>,
	},

	cpsEnergy: number,
	cpsRecords: {
		season: number,
		servers: Dictionary<{ rank: number, streak: number, score: number; }>,
	} | null,

	lastCpsReport: {
		sessionId: string,
		result: number,
		hitRankCap: boolean,

		newRank: number,
		newStreak: number,
		newScore: number,
		oldRank: number,
		oldScore: number,

		score: number,
		rankBonus: number,
		streakBonus: number,
		scoreBuff: number,
		streakBuff: number,
		totalScore: number,

		rewards: Dictionary<number>,

		oldRecord: {
			rank: number,
			score: number,
			streak: number,
		};
	} | null,

}
export interface StabfishDataRecord {
	mined?: number,
	rescued?: number,
	kills: number,
	stabs: number,
	killstreaks: number,
	stabstreaks: number,
	money: number,
	score: number,
	lastSurvivors: number,
	firstExplorer: number,
	nmsStage?: number,

	totalMined?: number,
	totalRescued?: number,
	totalStabs: number,
	totalKills: number,
	totalMoneyEarned: number,
	totalDBlessed?: number,
	totalChat?: number,

	amgdTimeUsed?: number,
	explorerTimeUsed?: number,
	totalSurvivors?: number,

	cpsRank?: number,
	cpsScore?: number,
	cpsStreak?: number,
}

export interface StabfishAccountInventory {
	version: number,
	totalMoneySpent: number,
	publicChestOpened: number,
	templeChestOpened: number,
	decoSets: Dictionary<string>,
	rarityCrafted: number[],
	crafted: number[],
	money: number,
	gem: number,
	equippedDecos: Dictionary<number>,
	availables: Dictionary<number>,
	orders: ItemCode[],
	skins: Dictionary<number>,
	skinOrders: number[],
	skinHistory: Dictionary<SkinHistory | undefined>,
	equippedSkins: Dictionary<SkinGroup | undefined>,
	bpData: BlackPearlData,
	decoLimit?: number,
	claimables?: Dictionary<'pending' | 'done'>,
	accuRewards?: Dictionary<Dictionary<number>>,
	inbox?: Dictionary<Mail>,
	debug?: any,

	daily: {
		date: string,
		publicChest: number,
		templeChest: number,
	};
	shop: { [T in ShopLimitedIds]: number },
}

export interface StabfishUserSettings {
	version: number,
	completedChallenges: string[],
	lastHeroes: FishType[],
	lastBossHeroes?: FishType[],
	tipsRead?: Dictionary<boolean>,
	contestRead?: number,
}

export interface StabfishMongoAccountData {
	version: number,
	name: string,
	uid: string,
	email: string,
	lvl: number,
	exp: number,
	teamId: string,
	unlockedFishes: Dictionary<boolean>,
	// isOnline: boolean,
	lastActive: number,
	firstRecord?: number,
	hero1?: FishType,
	hero2?: FishType,
	hero3?: FishType,
	record?: StabfishDataRecord,
	money: number,
	gem: number,
	decoSets: Dictionary<string>,
	equippedDecos: Dictionary<number>,
	equippedSkins: Dictionary<SkinGroup | undefined>,
	inventory: Dictionary<number>,
	rarityCrafted: number[],
	crafted: number[],
	skins: Dictionary<number>,
	bpData: BlackPearlData,
	quarters: number,
	isAnonymous: boolean,
	banned: boolean,
	decoLimit: number,
	skinProgress?: Dictionary<Dictionary<number>>,
	bossRecords?: Dictionary<Dictionary<BossRecord>>,
}
export interface BossRecord {
	timeUsed: number,
	frameUsed: number,
	wins: number,
}

export interface TeamMemberData {
	name: string,
	uid: string,
	index: number,
	server: string,
	isOnline: boolean,
}

export interface StabfishTeamDoc {
	version: number,
	tid: string,
	members: Dictionary<TeamMemberData>,
	passcode: string,
	lastActive: number,
}

export interface MaintenanceInfo {
	ongoing: false,
	start: number,
	end: number,
}
export interface VersionInfo {
	major: number,
	minor: number,
	patch: number,
}
export interface StabfishSystemDoc {
	version: number,
	maintenance: MaintenanceInfo,
	latestVersion: VersionInfo,
	stabfish1BVersion: VersionInfo,
}
export interface SkinHistory {
	recentDraws: number[],
	numDraws: number,
	news: number[],
	numRarity: Dictionary<number>,
}
export enum MailType {
	Custom,
	WeeklyContest,
}
export interface Mail {
	id: string,
	type: MailType,
	msg: string,
	sentTime: number,
	items?: Dictionary<number>,
	readTime?: number,
}

export interface PoqIndentity {
	id: string,
	gamerTag: string,
	avatar?: string, // Full URL: https://www.poq.gg/images/${id}/${avatar}
	email: string, // email scope only
}
export interface PoqWallet {
	balance: number,
	allocation: number,
}
export interface PoqTransactionResult {
	id: string,
}

export interface BlackPearlData {
	bought: number,
	earned: number,
	convert: number,
	spent: number,
	firstTime: Dictionary<number>,
	oneOff: number,
	win?: number,
}

export interface Sf1Info {
	id: string, uid?: string, name: string, bodyType: number, spearType: number, faceType: number, hatType: number, equippedSkins: Dictionary<SkinGroup>,
	lastPlay?: number,
}
export interface Sf1Data {
	playerInfo: Sf1Info,
	unlockedSpears: number[],
	unlockedBodies: boolean[],
	unlockedFaces: boolean[],
	unlockedHats: boolean[],
	unlockedMasters: boolean[],
	playerProgress: any,
}

export function getDefaultStabfishAccountData(uid: string): StabfishAccountData {
	return {
		version: 8,
		name: `Stabfish_${uid.slice(0, 3)}`,
		uid,
		exp: 0,
		lvl: 1,
		teamId: '',
		unlockedFishes: { 0: true, 1: true, 2: true, 3: true },
		unlockProgress: {},
		decoSets: {},
		lastServer: null,
		lastPlay: 0,
		lastSessionReport: null,
		nonAnonymous: false,
		banned: false,
		poq: null,
		mainQuestProgress: {},
		weeklyQuestProgress: {},
		timeLimitedQuestProgress: {},
		blueprintQuestProgress: {},
		weeklyQuestTime: 0,
		cpsEnergy: 0,
		cpsRecords: null,
		lastCpsReport: null,
	};
}

export function getEmptyInventory(): StabfishAccountInventory {
	return {
		version: 10,
		totalMoneySpent: 0,
		publicChestOpened: 0,
		templeChestOpened: 0,
		decoSets: {},
		crafted: [],
		rarityCrafted: [0, 0, 0, 0, 0],
		money: 0,
		gem: 0,
		equippedDecos: {},
		availables: {},
		orders: [],
		skins: {},
		skinOrders: [],
		skinHistory: {},
		equippedSkins: {},
		daily: {
			date: generateDateString(new Date()),
			publicChest: 0,
			templeChest: 0,
		},
		bpData: {
			bought: 0,
			earned: 0,
			convert: 0,
			spent: 0,
			oneOff: 0,
			firstTime: {},
		},
		shop: {},
	};
}

export function getDefaultStabfishSettings(): StabfishUserSettings {
	return {
		version: 0,
		completedChallenges: [],
		lastHeroes: [FishType.BlueShark, FishType.GreatWhiteShark, FishType.Megalodon],
	};
}

export function getEmptyTeam(tid: string): StabfishTeamDoc {
	return {
		version: 0,
		tid,
		lastActive: Date.now(),
		members: {},
		passcode: '',
	};
}
export function getChallengeProgress(progress: Dictionary<Dictionary<UnlockChallengeProgress[]>>, unlockId: number, step: number, index: number) {
	if (!progress[unlockId]) { progress[unlockId] = {}; }
	if (!progress[unlockId][step]) { progress[unlockId][step] = []; }
	if (!progress[unlockId][step][index]) {
		progress[unlockId][step][index] = {
			completed: false,
			current: 0,
		};
	}
	return progress[unlockId][step][index];
}
export function getQuestProgress(progress: Dictionary<QuestProgress>, unlockId: number) {
	if (!progress[unlockId]) { progress[unlockId] = { progresses: {}, currentStep: 0 }; }
	return progress[unlockId];
}
