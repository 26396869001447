import { tint } from '@/client/factory/helper';
import { disposeWallSprite, getWallSprite, MapAsset, WallSprite } from '@/client/factory/mapAssets';
import { MapData, NodeData, PartialMapData } from '@/game/multithread/roomMap';
import { TrackerData, TrackerType, ViewState } from '@/game/multithread/viewState';
import settingx from '@/store/modules/settingx';
import { getBoolean } from '@/util/number';
import { Container, Graphics, ParticleContainer, Sprite } from 'pixi.js';
import { TeamMemberInfo } from '../stageControl';
import { BroadcastEvent } from '@/game/infos/eventType';
import { Ease, Tween } from '@/util/tweents';
import { Preset } from '@/game/infos/preset';
import { BroadcastForecastArmageddonEvent, BroadcastForecastOuroborosOpenEvent, BroadcastForecastWhirlpoolEvent } from '@/game/multithread/state';

export class MiniMap extends Container {

	public maskRect = new Graphics();
	public blocks = new Graphics();
	public closed = new Graphics();
	public bg = new Graphics();
	public graphics = new Graphics();
	public container = new Container();
	public trackersContainer = new Container();
	public map = new Container();

	public indicator1 = getWallSprite(MapAsset.Arrow);
	public indicator2 = getWallSprite(MapAsset.Arrow);
	public indicator3 = getWallSprite(MapAsset.Arrow);
	public indicator4 = getWallSprite(MapAsset.Arrow);

	public boss = getWallSprite(MapAsset.Boss) as WallSprite;
	public whirlpool = getWallSprite(MapAsset.Whirlpool);
	public siren = getWallSprite(MapAsset.Siren);
	public ouroboros1 = getWallSprite(MapAsset.Ouroboros);
	public ouroboros2 = getWallSprite(MapAsset.Ouroboros);
	public whirlpoolNext = getWallSprite(MapAsset.Whirlpool);
	public sirenNext = getWallSprite(MapAsset.Siren);
	public ouroboros1Next = getWallSprite(MapAsset.Ouroboros);
	public ouroboros2Next = getWallSprite(MapAsset.Ouroboros);
	public armageddonNext = getWallSprite(MapAsset.Sector);

	protected mapData!: PartialMapData;
	protected closedSectors = 0;
	protected fullClosedSectors = 0;

	protected miniMapScale = 300 / 2047 / 6;
	protected miniMapSectorSize = 2047 * this.miniMapScale;

	protected mapMask = { t: -2047, b: 0, l: -2047, r: 0 };

	protected hazards = new Set<string>();
	protected ecologist = 0;

	constructor() {
		super();
		this.maskRect.beginFill(0);
		this.maskRect.drawRect(0, 0, 300, 200);

		this.container.addChild(this.blocks);
		this.container.mask = this.maskRect;
		this.map.addChild(this.container);
		this.map.addChild(this.maskRect);

		this.addChild(this.bg);
		this.addChild(this.map);
		this.addChild(this.graphics);
		this.addChild(this.closed);
		this.addChild(this.trackersContainer);
		this.alpha = 0.75;

		this.ouroboros1.scale.set(.75);
		this.ouroboros2.scale.set(.75);

		this.whirlpoolNext.alpha = .3;
		this.sirenNext.alpha = .3;
		this.ouroboros1Next.alpha = .3;
		this.ouroboros2Next.alpha = .3;
		this.armageddonNext.alpha = .3;
		this.armageddonNext.tint = 0xff6666;

		this.addChild(this.boss);
	}

	public update(teamMembers: TeamMemberInfo[], state: ViewState) {

		const scale = this.miniMapScale;
		const trackers = state.ts;
		this.boss.visible = false;

		for (let i = 0; i < 4; i++) {
			const indicator = this['indicator' + (i + 1)] as Sprite;
			const player = teamMembers[i];
			if (!player) {
				if (indicator.parent) {
					indicator.parent.removeChild(indicator);
				}
			} else {

				if (!indicator.parent) {
					this.addChild(indicator);
				}
				if (player.x < this.mapMask.l || player.x > this.mapMask.r || player.y < this.mapMask.t || player.y > this.mapMask.b) {
					indicator.visible = false;
				} else {
					indicator.visible = true;
					indicator.angle = player.deg;
					indicator.x = player.x * scale;
					indicator.y = player.y * scale;
					indicator.tint = settingx.now['color' + (i + 1)];
					indicator.scale.set(player.isMe ? 1.5 : 1);
				}
			}
		}

		const trackerLen = trackers.length;
		const trackerIconLen = this.trackersContainer.children.length;
		if (trackerLen > trackerIconLen) {
			for (let i = 0; i < trackerLen - trackerIconLen; i++) {
				const icon = getWallSprite(MapAsset.Target);
				this.trackersContainer.addChild(icon);
			}
		} else if (trackerLen < trackerIconLen) {
			for (let i = trackerIconLen - 1; i >= trackerLen; i--) {
				const icon = this.trackersContainer.children[i] as WallSprite;
				this.trackersContainer.removeChild(icon);
				disposeWallSprite(icon);
			}
		}

		for (let i = 0; i < trackerLen; i++) {

			const tracker = trackers[i];
			let icon = this.trackersContainer.children[i] as WallSprite;
			if (tracker.type === TrackerType.Boss) {
				icon.visible = false;
				icon = this.boss;
			} else {
				icon.tint = tracker.type === TrackerType.Armageddon ? 0xff3560 : 0xffff00;
			}
			icon.visible = true;
			if (tracker.x < this.mapMask.l || tracker.x > this.mapMask.r || tracker.y < this.mapMask.t || tracker.y > this.mapMask.b) {
				icon.visible = false;
			} else {
				icon.visible = true;
				icon.x = tracker.x * scale;
				icon.y = tracker.y * scale;
			}
		}

		if (this.whirlpool.parent) {
			if (state.rt > state.whirlpoolEndTime) {
				this.removeChild(this.whirlpool);
			} else {
				this.whirlpool.x = (state.whirlpoolX + .5) * this.miniMapSectorSize;
				this.whirlpool.y = (state.whirlpoolY + .5) * this.miniMapSectorSize;
			}
		} else {
			if (state.rt < state.whirlpoolEndTime) {
				this.addChild(this.whirlpool);
				this.whirlpool.x = (state.whirlpoolX + .5) * this.miniMapSectorSize;
				this.whirlpool.y = (state.whirlpoolY + .5) * this.miniMapSectorSize;
				if (state.whirlpoolSpawnTime > state.rt - 500) {

					this.whirlpool.scale.set(2);
					Tween.get(this.whirlpool.scale).to({ x: 1, y: 1 }, 500, Ease.circOut);
				} else {
					this.whirlpool.scale.set(1);
				}
			}
		}
		if (this.siren.parent) {
			if (state.rt > state.sirenEndTime) {
				this.removeChild(this.siren);
			} else {
				this.siren.x = (state.sirenX + .5) * this.miniMapSectorSize;
				this.siren.y = (state.sirenY + .5) * this.miniMapSectorSize;
			}
		} else {
			if (state.rt < state.sirenEndTime) {
				this.addChild(this.siren);
				this.siren.x = (state.sirenX + .5) * this.miniMapSectorSize;
				this.siren.y = (state.sirenY + .5) * this.miniMapSectorSize;
				if (state.sirenSpawnTime > state.rt - 500) {

					this.siren.scale.set(2);
					Tween.get(this.siren.scale).to({ x: 1, y: 1 }, 500, Ease.circOut);
				} else {
					this.siren.scale.set(1);
				}
			}
		}
		if (this.ouroboros1.parent) {
			if (state.ouroboros1X === -1) {
				this.removeChild(this.ouroboros1, this.ouroboros2);
			} else {
				this.ouroboros1.x = state.ouroboros1X * this.miniMapScale;
				this.ouroboros1.y = state.ouroboros1Y * this.miniMapScale;
				this.ouroboros2.x = state.ouroboros2X * this.miniMapScale;
				this.ouroboros2.y = state.ouroboros2Y * this.miniMapScale;
			}
		} else {
			const { ouroboros1, ouroboros2 } = this;
			if (state.ouroboros1X !== -1) {
				this.addChild(this.ouroboros1, this.ouroboros2);
				this.ouroboros1.x = state.ouroboros1X * this.miniMapScale;
				this.ouroboros1.y = state.ouroboros1Y * this.miniMapScale;
				this.ouroboros2.x = state.ouroboros2X * this.miniMapScale;
				this.ouroboros2.y = state.ouroboros2Y * this.miniMapScale;
				if (state.animateOuroborus) {
					ouroboros1.scale.set(1.5);
					ouroboros2.scale.set(1.5);
					Tween.get(ouroboros1.scale).to({ x: .75, y: .75 }, 500, Ease.circOut);
					Tween.get(ouroboros2.scale).to({ x: .75, y: .75 }, 500, Ease.circOut);
				} else {
					ouroboros1.scale.set(.75);
					ouroboros2.scale.set(.75);
				}
			}
		}
		if (state.ecologist > 0 && this.ecologist !== 2) {
			this.ecologist = state.ecologist;
			let dirty = false;
			const container = this.container;
			for (const hid in state.hazards) {
				if (Object.prototype.hasOwnProperty.call(state.hazards, hid)) {
					if (!this.hazards.has(hid)) {
						this.hazards.add(hid);
						dirty = true;
						const hazardData = state.hazards[hid];
						const icon = getWallSprite(MapAsset.Fire - 1 + hazardData.type, hazardData.x * scale, hazardData.y * scale);
						container.addChild(icon);
						icon.scale.set(.7);
					}
				}
			}

			if (dirty) {
				this.map.cacheAsBitmap = false;
				this.map.cacheAsBitmap = true;
			}
		}
	}

	public forecastWhirpool(e: BroadcastForecastWhirlpoolEvent, duration: number) {
		const icon = this.whirlpoolNext;
		this.addChild(icon);
		icon.x = (e.x + .5) * this.miniMapSectorSize;
		icon.y = (e.y + .5) * this.miniMapSectorSize;
		icon.scale.set(2);
		Tween.get(icon.scale).to({ x: 1, y: 1 }, 500, Ease.circOut);
		setTimeout(() => {
			this.removeChild(icon);
		}, duration);

	}
	public forecastSiren(e: BroadcastForecastWhirlpoolEvent, duration: number) {
		const icon = this.sirenNext;
		this.addChild(icon);
		icon.x = (e.x + .5) * this.miniMapSectorSize;
		icon.y = (e.y + .5) * this.miniMapSectorSize;
		icon.scale.set(2);
		Tween.get(icon.scale).to({ x: 1, y: 1 }, 500, Ease.circOut);
		setTimeout(() => {
			this.removeChild(icon);
		}, duration);
	}
	public forecastOuroborus(e: BroadcastForecastOuroborosOpenEvent, duration: number) {
		const ouroboros1 = this.ouroboros1Next;
		const ouroboros2 = this.ouroboros2Next;
		this.addChild(ouroboros1, ouroboros2);
		ouroboros1.x = e.x1 * this.miniMapScale;
		ouroboros1.y = e.y1 * this.miniMapScale;
		ouroboros2.x = e.x2 * this.miniMapScale;
		ouroboros2.y = e.y2 * this.miniMapScale;
		ouroboros1.scale.set(1.5);
		ouroboros2.scale.set(1.5);
		Tween.get(ouroboros1.scale).to({ x: .75, y: .75 }, 500, Ease.circOut);
		Tween.get(ouroboros2.scale).to({ x: .75, y: .75 }, 500, Ease.circOut);
		setTimeout(() => {
			this.removeChild(ouroboros1, ouroboros2);
		}, duration);
	}
	public forecastArmageddon(e: BroadcastForecastArmageddonEvent, duration: number) {
		const icon = this.armageddonNext;
		this.addChild(icon);
		icon.x = e.x * this.miniMapSectorSize;
		icon.y = e.y * this.miniMapSectorSize;
		icon.scale.x = 0.05;
		if (e.d === 1) {
			icon.x += this.miniMapSectorSize;
		} else if (e.d === 2) {
			icon.x += this.miniMapSectorSize;
			icon.y += this.miniMapSectorSize;
		} else if (e.d === 3) {
			icon.y += this.miniMapSectorSize;
		}
		icon.angle = e.d * 90;
		setTimeout(() => {
			this.removeChild(icon);
		}, duration);
	}
	public updateArmageddon(state: ViewState) {
		const scale = 300 / 2047 / 6;
		const blockSize = 341 * scale;
		const sectorSize = 2047 * scale;
		const { w, h } = this.mapData;
		const len = w * h;
		const graphics = this.graphics;
		if (state.closedSectors !== this.closedSectors) {
			this.closed.beginFill(0xff0000, 0.35);
			const toAdd = state.closedSectors ^ this.closedSectors;
			this.closedSectors = state.closedSectors;

			for (let i = 0; i < len; i++) {
				if (getBoolean(toAdd, i)) {
					const y = Math.floor(i / w);
					const x = i % w;
					this.closed.drawRect(x * sectorSize, y * sectorSize, sectorSize, sectorSize);
				}
			}
			this.bg.clear();
			graphics.clear();
		}
		if (state.closedSectors !== this.fullClosedSectors) {
			graphics.clear();
			graphics.beginFill(0xff0000, 0.35);
			let ox = state.closingSectorX;
			let oy = state.closingSectorY;
			let ow = 1;
			let oh = 1;
			const direction = state.closingSectorDirection;
			const progress = (state.rt - state.closingSectorStartTime) / (state.closingSectorEndTime - state.closingSectorStartTime);
			if (direction === 3) {
				oy += 1 - progress;
				oh = progress;
			} else if (direction === 1) {
				oh = progress;
			} else if (direction === 2) {
				ox += 1 - progress;
				ow = progress;
			} else {
				ow = progress;
			}
			graphics.drawRect(ox * sectorSize, oy * sectorSize, ow * sectorSize, oh * sectorSize);
		}
		if (this.armageddonNext.parent) {
			this.armageddonNext.scale.x += .025;
			if (this.armageddonNext.scale.x > 1) {
				this.armageddonNext.scale.x = .05;
			}
		}
	}

	public build(mapData: PartialMapData) {
		this.closedSectors = 0;
		this.fullClosedSectors = 0;

		this.map.cacheAsBitmap = false;
		this.mapData = mapData;

		const scale = 300 / 2047 / 6;
		const blockSize = 341 * scale;
		const sectorSize = 2047 * scale;

		const container = this.container;
		const blocks = this.blocks;
		blocks.clear();
		blocks.beginFill(0x1e2f3b, 0.7);
		blocks.drawRect(0, 0, 300, 200);
		container.removeChildren();
		container.addChild(blocks);

		const { sectors, w, h, safe } = mapData;
		this.mapMask.b = (h + 1) * 2047;
		this.mapMask.r = (w + 1) * 2047;
		this.fullClosedSectors = 0;
		for (let i = 0; i < w * h; i++) {
			this.fullClosedSectors |= (1 << i);
		}

		this.closed.clear();
		this.graphics.clear();
		this.bg.clear();
		if (safe) {
			this.bg.beginFill(0xffffff, 0.3);
			this.bg.drawRect(0, 0, sectorSize * w, sectorSize);
			this.bg.beginFill(0x00ff00, 0.5);
			this.bg.arc(safe.x * sectorSize + sectorSize / 2, safe.y * sectorSize, sectorSize / 1.85, 0, Math.PI);
			this.bg.lineStyle(1, 0xffffff, 0.3);
			this.bg.moveTo(0, sectorSize);
			this.bg.lineTo(w * sectorSize, sectorSize);
			this.bg.lineStyle(0);
		}


		const addMainNode = (x: number, y: number, node: NodeData) => {
			if (node.t) {
				container.addChild(getWallSprite(MapAsset.MiniMainNodeT, x, y - blockSize));
			}
			if (node.b) {
				container.addChild(getWallSprite(MapAsset.MiniMainNodeB, x, y + blockSize));
			}
			if (node.l) {
				container.addChild(getWallSprite(MapAsset.MiniMainNodeL, x - blockSize, y));
			}
			if (node.r) {
				container.addChild(getWallSprite(MapAsset.MiniMainNodeR, x + blockSize, y));
			}
			container.addChild(getWallSprite(MapAsset.MiniMainNode, x, y));
		};
		const addTopNode = (x: number, y: number, node: NodeData) => {
			if (node.l) {
				container.addChild(getWallSprite(MapAsset.MiniTopNodeL, x - blockSize, y));
			}
			if (node.r) {
				container.addChild(getWallSprite(MapAsset.MiniTopNodeR, x + blockSize, y));
			}
			container.addChild(getWallSprite(MapAsset.MiniTopNode, x, y));
		};
		const addLeftNode = (x: number, y: number, node: NodeData) => {
			if (node.t) {
				container.addChild(getWallSprite(MapAsset.MiniLeftNodeT, x, y - blockSize));
			}
			if (node.b) {
				container.addChild(getWallSprite(MapAsset.MiniLeftNodeB, x, y + blockSize));
			}
			container.addChild(getWallSprite(MapAsset.MiniLeftNode, x, y));
		};
		blocks.beginFill(0);
		for (let x = 0; x < w + 1; x++) {
			for (let y = 0; y < h + 1; y++) {
				const sector = sectors[x][y];
				if (sector.blocked) {
					blocks.drawRect(x * sectorSize, y * sectorSize, sectorSize, sectorSize);
				}
				if (sector.nodes.tl) {
					addMainNode(x * sectorSize, y * sectorSize, sector.nodes.tl);
				}
				if (sector.nodes.tc) {
					addTopNode(x * sectorSize + sectorSize / 2, y * sectorSize, sector.nodes.tc);
				}
				if (sector.nodes.cl) {
					addLeftNode(x * sectorSize, y * sectorSize + sectorSize / 2, sector.nodes.cl);
				}
			}
		}

		this.map.cacheAsBitmap = true;
		this.siren.parent?.removeChild(this.siren);
		this.whirlpool.parent?.removeChild(this.whirlpool);
		this.ouroboros1.parent?.removeChild(this.ouroboros1);
		this.ouroboros2.parent?.removeChild(this.ouroboros2);
		this.ecologist = 0;
		this.hazards.clear();
	}
}
