import { Point } from '@/util/rotate';

/** this is synced with client, dont simply change position */
export enum EventType {
	BodyHitWall,
	Damage,
	HazardDamage,
	StunEvent,
	SpearHitSpear,
	Stab,
	StababDeath,
	PlayerDeath,
	Bledout,
	SpearHitOre,
	SpearHitBottle,

	Loot,
	Buff,
	Emoji,

	PublicChest,

	CorpseToFood,

	JoinTempTeam,
	NewMember,
	SpikeDamage,

	DivineBlessing,
	Chat,
	Mute,
	SummonNemesis,

	Bonk,
	SecretRoomData,
	SecretRoomFound,
	TempleChest,

	Whirlpool,
	Siren,
	OuroborosOpen,
	OuroborosClose,

	ArmageddonExpose,
	BossDamage,
	NonPlayerDamager,

	SwordBreak,

	DojoCreated,
	DojoInvited,
	DojoKicked,
	DojoDisband,
	DojoHostChange,
	DojoInvites,
	DojoSpectate,

	SpearHitShield,
	Tail,
	Jobuff,
	SpearParried,

	RecessGene,

	WhirlpoolForecast,
	SirenForecast,
	OuroborosForecast,
	ArmageddonForcast,
}

export interface BroadcastEvent {
	type: EventType,
	t: number,
	pos?: Point,
	scr?: boolean,
}

export interface GameEvent {
	type: EventType,
	time: number,
}

export enum MuteType {
	ByYou,
	You,
	All,
}
export enum ChatType {
	Public,
	System,
	ChatSystem,
	Warning,
}
