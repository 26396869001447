import { Dictionary } from '@/util/dictionary';
import { DecoSet } from '../infos/decorativeInfos';
import { PlayerMode, PlayerType, StababType } from '../infos/enums';
import { BroadcastEvent } from '../infos/eventType';
import { FishType } from '../infos/fishInfos';
import { FoodType } from '../infos/foodInfos';
import { HazardType } from '../infos/hazardInfos';
import { OreColor } from '../infos/oreInfo';
import { SkinGroup } from '../infos/skinInfos';
import { SpearType } from '../infos/spearInfos';
import { MiscData } from './skills/miscContainer';
import { PlayerGameState, ScoreboardData, SkillData, SpecialSkillData } from './state';

export function getDefaultViewState(): ViewState {
	return {

		sid: '',
		tid: '',
		ranks: [],
		evts: [],
		players: {},
		foods: {},
		miscs: {},
		ps: [],
		fds: [],
		mis: [],
		ts: [],
		ors: [],
		bottles: [],
		team: [],
		hzds: [],
		hazards: {},
		us: [],

		playerType: PlayerType.None,
		playerMode: PlayerMode.Enemy,
		life0: 1,
		life1: 2,
		life2: 3,

		t: 0,
		rt: 0,
		rank: 0,
		closeTime: 0,


		life: 0,
		state: PlayerGameState.None,
		stabs: 0,
		streak: 0,
		kills: 0,
		kStreak: 0,
		hscore: 0,
		scoreL: 0,
		money: 0,
		sightRadius: 0,
		sightRadiusAfterBlind: 0,


		skill1: 0,
		skillActive1: false,
		skillDisabled1: false,
		skill2: 0,
		skillActive2: false,
		skillCooldown2: 0,
		skillDisabled2: false,
		skill3: 0,
		skillActive3: false,
		skillCooldown3: 0,
		skillDisabled3: false,

		vultrMaintenance: false,

		armageddonStarted: false,
		closedSectors: 0,
		closingSectorX: 0,
		closingSectorY: 0,
		closingSectorDirection: 3,
		closingSectorStartTime: 0,
		closingSectorEndTime: 0,

		pid: 0,
		x: 0,
		y: 0,
		hp: 0,
		stamina: 0,
		blood: 0,
		buffs: '',
		oxy: 1,
		reinc: 0,

		armageddonPlace: 0,
		armageddonStartTime: 0,
		numPlayers: 0,
		numZombies: 0,

		publicChestX: 0,
		publicChestY: 0,
		publicChestOpened: false,
		templeChestOpened: false,

		spearsBought: 1,
		spearType: 0,

		animateOuroborus: true,
		ouroboros1X: -1,
		ouroboros1Y: -1,
		ouroboros2X: -1,
		ouroboros2Y: -1,
		sirenEndTime: 0,
		sirenSpawnTime: 0,
		sirenX: -1,
		sirenY: -1,
		whirlpoolEndTime: 0,
		whirlpoolSpawnTime: 0,
		whirlpoolX: -1,
		whirlpoolY: -1,

		explorerName: '',
		explorerPid: 0,
		explorerUid: '',
		idolDirection: 0,
		scrD: 0,
		scrX: -100,
		scrY: -100,

		cpsPlace: -2,
		opponentCurrentLife: 0,
		opponentHpPercent: 255,
		timeResult: 0,

		dojoId: 0,
		ecologist: 0,
	};
}

export interface ViewState extends HalfViewState, MyData {

}
export interface HalfViewState {

	state: PlayerGameState,
	t: number,
	rt: number,
	ranks: ScoreboardData[],
	evts: BroadcastEvent[],
	ps: PartialPlayerData[],
	fds: PartialFoodData[],
	mis: PartialMiscData[],
	ts: TrackerData[],
	ors: OreData[],
	bottles: BottleData[],
	hzds: HazardData[],
	us: UserData[],

	hazards: Dictionary<HazardData>,
	players: Dictionary<PlayerData>,
	foods: Dictionary<FoodData>,
	miscs: Dictionary<MiscData>,
}
export interface MyData {

	// string
	sid: string,
	tid: string,
	buffs: string,
	team: number[],
	explorerUid: string,
	explorerName: string,

	// bigint
	reinc: number,
	closeTime: number,
	armageddonStartTime: number,

	closingSectorStartTime: number,
	closingSectorEndTime: number,
	timeResult: number,

	whirlpoolSpawnTime: number,
	whirlpoolEndTime: number,
	sirenSpawnTime: number,
	sirenEndTime: number,

	// int32
	pid: number,
	stabs: number,
	streak: number,
	kills: number,
	kStreak: number,
	hscore: number,
	scoreL: number,
	money: number,

	x: number, // x10
	y: number, // x10

	closedSectors: number,

	publicChestX: number,
	publicChestY: number,

	ouroboros1X: number,
	ouroboros1Y: number,
	ouroboros2X: number,
	ouroboros2Y: number,
	explorerPid: number,

	// int16
	sightRadius: number,
	sightRadiusAfterBlind: number,
	hp: number,
	spearsBought: number,

	idolDirection: number,

	// int8
	spearType: SpearType,

	playerType: PlayerType,
	playerMode: PlayerMode,
	life0: number,
	life1: number,
	life2: number,
	rank: number,
	life: number,

	skill1: number,
	skill2: number,
	skill3: number,
	skillActive1: boolean,
	skillDisabled1: boolean,
	skillActive2: boolean,
	skillDisabled2: boolean,
	skillActive3: boolean,
	skillDisabled3: boolean,

	closingSectorX: number,
	closingSectorY: number,
	closingSectorDirection: number,
	armageddonStarted: boolean,

	vultrMaintenance: boolean,

	skillCooldown3: number, // x100
	skillCooldown2: number, // x100
	stamina: number, // x100
	oxy: number, // x100
	blood: number, // x100

	numZombies: number,
	numPlayers: number,
	armageddonPlace: number,

	publicChestOpened: boolean,
	templeChestOpened: boolean,

	whirlpoolX: number,
	whirlpoolY: number,
	sirenX: number,
	sirenY: number,
	animateOuroborus: boolean,

	scrX: number, // -
	scrY: number, // -
	scrD: number, // -

	cpsPlace: number,
	opponentCurrentLife: number,
	opponentHpPercent: number, // 255

	dojoId: number,
	ecologist: number,
}

export interface UpdateMyData extends Partial<MyData> {
	playerRemove?: number[],
	foodRemove?: number[],
	miscRemove?: number[],
	userRemove?: string[],
	userAdd?: UserData[],
}

export interface UpdateState extends Partial<HalfViewState> {

	t: number,
	rt: number,
	my: UpdateMyData;
}

export interface PartialPlayerData extends Partial<PlayerData> {
	id: number,
	sbs?: PartialStababData[],
}
export interface PartialStababData extends Partial<StababData> {
	id: number,
}
export interface PartialFoodData extends Partial<FoodData> {
	id: number,
}
export interface PartialMiscData extends Partial<MiscData> {
	id: number,
}

export function getDefaultPlayerData(): PlayerData {
	return {

		name: '',
		tid: '',
		decoSet: '',

		id: NaN,

		noobLvl: 0,
		isModerator: false,
		isDraco: false,
		isAdmin: false,

		spearType: 0,
		fishType: 0,
		skinGroup: 0,
		playerType: PlayerType.Player,
		mobLvl: 0,

		maxHp: 0,
		skill1: false,
		skill2: false,
		skill3: false,
		spd: 0,
		spearLvl: 0,
		hasSpear: false,
		frozen: false,
		burn: false,
		paralyzed: false,
		blind: false,
		zompkin: false,
		isFishBolt: false,
		isHealing: false,
		isWrapped: false,
		stababId: 0,

		blink: 0,
		stun: 0,
		x: 0,
		y: 0,
		hp: 0,
		bodyRadius: 0,
		spearWidth: 0,
		spearHeight: 0,
		deg: 0,
		nmsStage: 0,

		spearTemparature: 0,
		spearElectrified: false,

		stababs: {},
		jobuff: 0,
		assassinLvl: 0,
		daredevilLvl: 0,

	};
}
export interface UserData {
	uid: string,
	name: string,
	muted: boolean,
	mutedYou: boolean,
	mutedAll: boolean,
	dojoId: number,
}

export interface PlayerData {

	// string
	name: string,
	tid: string,
	decoSet: string,

	// bigInt
	blink: number,

	// int32
	id: number,
	stababId: number,
	maxHp: number,
	hp: number,

	x: number, // x10
	y: number, // x10

	// int16
	stun: number,
	bodyRadius: number,
	spearWidth: number,
	spearHeight: number,
	deg: number,
	spearTemparature: number,
	nmsStage: number,

	// int8
	spearType: SpearType,
	spearElectrified: boolean,
	fishType: FishType,
	skinGroup: SkinGroup,
	playerType: PlayerType,
	mobLvl: number,
	skill1: boolean,
	skill2: boolean,
	skill3: boolean,
	spearLvl: number,
	hasSpear: boolean,
	frozen: boolean,
	burn: boolean,
	paralyzed: boolean,
	blind: boolean,
	zompkin: boolean,

	noobLvl: number,
	isAdmin: boolean,
	isModerator: boolean,
	isDraco: boolean,
	isHealing: boolean,
	isFishBolt: boolean,
	isWrapped: boolean,

	spd: number, // x10

	stababs: Dictionary<StababData>,
	jobuff: number, // 10 number, uint
	assassinLvl: number,
	daredevilLvl: number,
}
export function getDefaultStababData() {
	return {
		t: 0,
		ft: 0,
		pid: NaN,
		id: NaN,
		d: 0,

		dist: 0,
		scale: 1,
		state: StababAction.Normal,
		zompkin: 0,
	};
}
export interface StababData {
	t: StababType,
	ft: FishType,
	pid: number,
	id: number,
	d: number,

	dist: number,
	scale: number,
	state: StababAction,
	zompkin: number,
}
export enum StababAction {
	Normal,
	Delay,
	Experdite,
	Dead,
}


export function getDefaultFoodData(): FoodData {
	return {
		ft: FoodType.Algae,
		id: NaN,
		x: 0,
		y: 0,
		tx: NaN,
		ty: NaN,
		eatenTime: 0,
		deathTime: 0,
	};
}
export interface FoodData {
	ft: FoodType,
	id: number,

	x: number,
	y: number,
	tx: number,
	ty: number,
	eatenTime: number,
	deathTime: number,
}
export enum TrackerType {
	None,
	Champion,
	Revenge,
	Armageddon,
	Nemesis,
	Boss,
}

export interface TrackerData {
	id: number,
	type: TrackerType,
	x: number,
	y: number,
}

export interface OreData {
	id: number,
	color: OreColor,
	x: number,
	y: number,
}
export interface BottleData {
	id: number,
	x: number,
	y: number,
}
export interface HazardData {
	id: number,
	x: number,
	y: number,
	deg: number,
	type: HazardType,
	active: boolean,
}
