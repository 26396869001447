import { DpadSymbol } from './assets/controls/DpadMixin';
import { EmojiBubble } from './assets/controls/emojiBubbleMixin';
import { SkillButtonSymbol } from './assets/controls/SkillButtonMixin';
import { UiControlSymbol } from './assets/controls/UiControlMixin';
// import { WsBadgeDraw, WsBadgeLeft, WsBadgeRight, WsBadgeScene, WsRankBadge } from './assets/cpsWsBadgeControl';
import { AllEmojis } from './assets/emojiAssets';
import { BlindedEffect, BrokenSword, BurningEffect, ElectricHazardSymbol, FireHazardSymbol, FrozenEffect, IceHazardSymbol, ParalyzedEffect, PuffEffect, WrappedEffect, ZompkinEffect } from './assets/hazardMixin';
import { OuroborosSymbol, SirenSymbol, WhirlpoolSymbol } from './assets/npcAssets';
import { BossLabelSymbol, PlayerLabelSymbol } from './assets/playerLabelMixin';
// import { PlayerSymbol, SpearFront } from './assets/playerMixin copy';
import { AllSpears, AllBodies, EvolveEffect, MiniSpike, StunAnimated, OnifuguSpikes, BodySlamEffect, KrakenRunSymbol, DragonTridentSymbol } from './assets/playerModel';
import { ClickFinger, PublicChest, TempleChest } from './assets/publiChestMixin';
import { ReincarnationContainer } from './assets/reincarnationContainerMixin';
import { ModeBanner, SafeZoneSymbol } from './assets/safeZoneMixin';
import { ShootingStarSymbol } from './assets/shootingStarMixin';
import { ChargedCloudSymbol, EmberCloudSymbol, FrostCloudSymbol } from './assets/skills/mcHazardCloudSymbol';
import { McIronmoniteSymbol, McIronWaveSymbol } from './assets/skills/mcIronmoniteSymbol';
import { ZellyfishSymbol } from './assets/skills/mcZellyfishSymbol';
import { SsBatDartSymbol } from './assets/skills/ssBatDartSymbol';
import { SsBodySlamSymbol, SsJomamaShieldSymbol } from './assets/skills/ssBodySlamSymbol';
import { SsDartSymbol } from './assets/skills/ssDartSymbol';
import { SsElectricDartSymbol } from './assets/skills/ssElectricDartSymbol';
import { SsElectricFieldSymbol } from './assets/skills/ssElectricFieldSymbol';
import { SsFireBulletSymbol, SsIceBulletSymbol } from './assets/skills/ssFireBulletSymbol';
import { SsForceFieldSymbol } from './assets/skills/ssForceFieldSymbol';
import { SsInkSacSymbol } from './assets/skills/ssInkSacSymbol';
import { SsMegaBiteSymbol } from './assets/skills/ssMegaBiteSymbol';
import { SsSunPunchSymbol } from './assets/skills/ssSunPunchSymbol';
import { SsSwimmingFangSymbol } from './assets/skills/ssSwimmingFangSymbol';
import { SsTridentWaveSymbol } from './assets/skills/ssTridentWaveSymbol';
import { SsWhammyWaveSymbol } from './assets/skills/ssWhammyWaveSymbol';
import { TeamMateIndicator } from './assets/teamMateIndicatorMixin';
import { StarSymbol, UnlockAnimation } from './assets/unlock/unlockAnimationMixin';
import { GachaAnimation, GachaReveal, GachaRevealSkin, GachaSkinLabel, PortalScene, SideScene } from './gachaAssets';
import { Iceberg, MechguinBackDoor, MechguinBody, MechguinExplosion, MechguinFaintedCaptain, MechguinFlag, MechguinHead, MechguinHeadSupport, MechguinLaserGun, MechguinLaserGunHand, MechguinMissile, MechguinTurret, MechguinWheels, MechguinWing } from './assets/boss/xmasPenguin/xmasPenguinAssets';
import { MechguinCrewWhiteFlag, PenguinCrewSymbol } from './assets/boss/xmasPenguin/biPenguinCrewSymbol';
import { MechguinTurretBulletSymbol } from './assets/boss/xmasPenguin/biMechguinBulletSymbol';
import { BiMechguinSnowBallSymbol } from './assets/boss/xmasPenguin/biSnowBallSymbol';
import { SuperPearlRess, SuperPearlStamina } from './assets/boss/superPearlSymbol';
import { MechguinAtomExplosion } from './assets/boss/xmasPenguin/biAtomBombSymbol';
import { SsMosaBiteSymbol } from './assets/skills/ssMosaBiteSymbol';
import { DiamondShield } from './assets/skills/SsDiamondHardenSymbol';
import { SsTentaBolaSymbol } from './assets/skills/ssTentaBolasSymbol';
import { Pumpkinbab } from '../controls/stababControl';
import { SsSnowballSymbol } from './assets/skills/ssSnowballSymbol';



export const googleFonts: string[] = ['Poppins', 'Titan One'];
export const mixinList: any = {
	// WsBadgeScene,
	// WsBadgeDraw,
	// WsBadgeLeft,
	// WsBadgeRight,
	// WsRankBadge,
	// Particle1Symbol: MovieClip,
	AllBodies,
	AllSpears,
	EvolveEffect,
	MiniSpike,
	StunAnimated,
	OnifuguSpikes,
	DragonTridentSymbol,
	DiamondShield,
	BodySlamEffect,
	KrakenRunSymbol,

	// PlayerSymbol,
	// SpearFront,
	DpadSymbol,
	UiControlSymbol,
	SkillButtonSymbol,
	PlayerLabelSymbol,
	TeamMateIndicator,
	ReincarnationContainer,

	SsMegaBiteSymbol,
	SsSwimmingFangSymbol,
	SsWhammyWaveSymbol,
	SsInkSacSymbol,
	SsBodySlamSymbol,
	SsJomamaShieldSymbol,
	SsForceFieldSymbol,
	SsElectricFieldSymbol,
	SsDartSymbol,
	SsBatDartSymbol,
	SsElectricDartSymbol,
	SsMosaBiteSymbol,

	UnlockAnimation,
	StarSymbol,
	EmojiBubble,
	AllEmojis,

	PublicChest,
	TempleChest,
	ClickFinger,
	SafeZoneSymbol,

	IceHazardSymbol,
	FireHazardSymbol,
	ElectricHazardSymbol,
	BurningEffect,
	FrozenEffect,
	ParalyzedEffect,
	PuffEffect,
	BlindedEffect,
	WrappedEffect,
	ZompkinEffect,
	ChargedCloudSymbol,
	FrostCloudSymbol,
	EmberCloudSymbol,

	GachaAnimation,
	PortalScene,
	SideScene,
	GachaReveal,
	GachaRevealSkin,
	GachaSkinLabel,

	ShootingStarSymbol,
	SsTridentWaveSymbol,

	WhirlpoolSymbol,
	OuroborosSymbol,
	SirenSymbol,
	McIronmoniteSymbol,
	McIronWaveSymbol,
	ZellyfishSymbol,

	SsFireBulletSymbol,
	SsIceBulletSymbol,
	SsSunPunchSymbol,

	MechguinHead,
	MechguinBody,
	MechguinWheels,
	MechguinBackDoor,
	MechguinWing,
	MechguinMissile,
	MechguinTurret,
	MechguinLaserGun,
	PenguinCrewSymbol,
	MechguinFaintedCaptain,
	MechguinHeadSupport,
	MechguinLaserGunHand,
	MechguinTurretBulletSymbol,
	MechguinFlag,
	MechguinCrewWhiteFlag,
	BiMechguinSnowBallSymbol,
	BossLabelSymbol,
	Iceberg,
	MechguinExplosion,
	MechguinAtomExplosion,

	SuperPearlRess,
	SuperPearlStamina,

	ModeBanner,
	BrokenSword,
	SsTentaBolaSymbol,
	Pumpkinbab,
	SsSnowballSymbol,
};
