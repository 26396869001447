import { SpecialSkill, NormalSkill, PassiveSkill } from '@/game/infos/skills';
import { nonReact } from '@/util/nonReact';
import { MovieClip } from 'pixi-animate';
import { DisplayObject, Sprite } from 'pixi.js';
import { getAllTimedChildren } from './helper';
import { registerWallSprites } from './mapAssets';
import { ParticleData } from './particles';

export enum SkillBgAsset {
	Special,
	Gene,
	Passive,
	Buff,
	RGene,
}

export enum NormalSkillAsset {
	Unknown,
	BleedExperdite,
	BleedDelay,
	Boost,
	ShellHiding,
}
export enum SpecialSkillAsset {
	Unknown,
	Ration,
	Bandage,
	Medkit,
	WhammyWave,
	SwimmingFang,
	MegaBite,
	SpinDodge,
	SpikeArmor,
	HolySword,
	DiamondHarden,
	Burst,
	InkSac,
	BodySlam,
	Sacrifice,
	TempSafeZone,
	ForceField,
	ElectricField,
	MercyRelease,
	Dart,
	BatDart,
	ElectricDart,
	FishBolt,
	TridentWave,
	FireBullet,
	SunPunch,
	Decoy,
	TentaBola,
	Disguise,
	SwordDance,
	StrongArms,
	MosaBite,
	DragonTrident,
	Snowball,
}

const normals: ParticleData[] = nonReact([]);
const normalPools: SkillSprite[][] = nonReact([]);
const specials: ParticleData[] = nonReact([]);
const specialPools: SkillSprite[][] = nonReact([]);
const passives: ParticleData[] = nonReact([]);
const passivePools: SkillSprite[][] = nonReact([]);
const lvls: ParticleData[] = nonReact([]);
const lvlPools: SkillSprite[][] = nonReact([]);

const bgPools: SkillBgSprite[][] = nonReact([[], [], [], [], []]);

export interface SkillSprite extends Sprite {
	type: 'normal' | 'special' | 'passive' | 'lvl',
	spriteId: NormalSkillAsset | SpecialSkillAsset | PassiveSkill,
}
export interface SkillBgSprite extends Sprite {
	spriteId: SkillBgAsset;
}

function getSprite(index: number, list: ParticleData[], pools: SkillSprite[][], type: 'normal' | 'special' | 'passive' | 'lvl') {
	const pool = pools[index];
	let symbol = pool.shift();

	const data = list[index];
	if (!symbol) {
		symbol = Sprite.from(data.texture) as SkillSprite;
	}
	symbol.anchor.set(data.u, data.v);
	symbol.spriteId = index;
	symbol.type = type;

	return symbol;
}
export function getNormalSkillSprite(index: NormalSkillAsset) {
	const list = normals;
	const pools = normalPools;
	return getSprite(index, list, pools, 'normal');
}
export function getSpecialSprite(index: SpecialSkillAsset) {
	const list = specials;
	const pools = specialPools;
	return getSprite(index, list, pools, 'special');
}
export function getPassiveSprite(index: PassiveSkill) {
	const list = passives;
	const pools = passivePools;
	return getSprite(index, list, pools, 'passive');
}
export function getSkillLvl(lvl: number) {
	const list = lvls;
	const pools = lvlPools;
	return getSprite(lvl - 1, list, pools, 'lvl');
}

export function disposeSkillSprite(skillSprite: DisplayObject) {
	const symbol = skillSprite as SkillSprite;
	symbol.tint = 0xffffff;
	const pools =
		symbol.type === 'special' ? specialPools :
			symbol.type === 'normal' ? normalPools :
				symbol.type === 'passive' ? passivePools :
					lvlPools;
	pools[symbol.spriteId].push(symbol);
}
export function getSkillBg(index: SkillBgAsset) {

	let symbol = bgPools[index].shift() as SkillBgSprite;
	if (!symbol) {
		symbol = Sprite.from(
			index === SkillBgAsset.Buff ? 'mainAssets_SB-Buff' :
				index === SkillBgAsset.Special ? 'mainAssets_SB-SpecialSkill' :
					index === SkillBgAsset.Gene ? 'mainAssets_SB-Gene' :
						index === SkillBgAsset.RGene ? 'mainAssets_SB-RGene' :
							'mainAssets_SB-PassiveSkill',
		) as SkillBgSprite;
		symbol.anchor.set(0.5, 0.5);
		symbol.spriteId = index;
	}
	return symbol;
}
export function disposeSkillBg(skillBg: DisplayObject) {
	const symbol = skillBg as SkillBgSprite;
	bgPools[symbol.spriteId].push(symbol);
}

function registerSprites(symbol: MovieClip, list: ParticleData[], pools: Sprite[][]) {

	const timeline = (symbol as any)._timelines;
	const children = getAllTimedChildren(symbol);
	for (let i = 0; i < children.length; i++) {
		const child = children[i];
		if (i === 0) { child.x = child.y = 0; }
		const pos = timeline[i]?._currentProps || { x: 0, y: 0 };
		const rect = child.getLocalBounds();
		const u = -(child.x + pos.x || 0) / rect.width;
		const v = -(child.y + pos.y || 0) / rect.height;
		const texture = (child as Sprite).texture;
		list[i] = { u, v, texture };
		pools[i] = [];
	}
	// console.log(symbol);
	symbol.destroy();
}
export function registerNormalSkillSprites(symbol: MovieClip) {
	const list = normals;
	const pools = normalPools;
	registerSprites(symbol, list, pools);
}
export function registerSpecialSprites(symbol: MovieClip) {
	const list = specials;
	const pools = specialPools;
	registerSprites(symbol, list, pools);
}
export function registerPassiveSprites(symbol: MovieClip) {
	const list = passives;
	const pools = passivePools;
	registerSprites(symbol, list, pools);
}
export function registerSkillLvlSprites(symbol: MovieClip) {
	const list = lvls;
	const pools = lvlPools;
	registerSprites(symbol, list, pools);
}

export interface SkillAssetInfo {
	asset: number,
	lvl: number,
}

export const normalSkillAssets: SkillAssetInfo[] = [];
normalSkillAssets[NormalSkill.None] = {
	asset: NormalSkillAsset.Unknown,
	lvl: 0,
};
normalSkillAssets[NormalSkill.BleedDelay] = {
	asset: NormalSkillAsset.BleedDelay,
	lvl: 0,
};
normalSkillAssets[NormalSkill.BleedExperdite] = {
	asset: NormalSkillAsset.BleedExperdite,
	lvl: 0,
};
normalSkillAssets[NormalSkill.Boost] = {
	asset: NormalSkillAsset.Boost,
	lvl: 0,
};
normalSkillAssets[NormalSkill.Boost2] = {
	asset: NormalSkillAsset.Boost,
	lvl: 2,
};
normalSkillAssets[NormalSkill.ShellHiding] = {
	asset: NormalSkillAsset.ShellHiding,
	lvl: 0,
};
export const specialSkillAssets: SkillAssetInfo[] = [];
specialSkillAssets[SpecialSkill.None] = {
	asset: SpecialSkillAsset.Unknown,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.Ration] = {
	asset: SpecialSkillAsset.Ration,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.Ration2] = {
	asset: SpecialSkillAsset.Ration,
	lvl: 2,
};
specialSkillAssets[SpecialSkill.Bandage] = {
	asset: SpecialSkillAsset.Bandage,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.Medkit] = {
	asset: SpecialSkillAsset.Medkit,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.WhammyWave] = {
	asset: SpecialSkillAsset.WhammyWave,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.SwimmingFang] = {
	asset: SpecialSkillAsset.SwimmingFang,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.MegaBite] = {
	asset: SpecialSkillAsset.MegaBite,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.SpinDodge] = {
	asset: SpecialSkillAsset.SpinDodge,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.SpikeArmor] = {
	asset: SpecialSkillAsset.SpikeArmor,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.DiamondHarden] = {
	asset: SpecialSkillAsset.DiamondHarden,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.HolySword] = {
	asset: SpecialSkillAsset.HolySword,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.Burst] = {
	asset: SpecialSkillAsset.Burst,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.InkSac] = {
	asset: SpecialSkillAsset.InkSac,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.BodySlam] = {
	asset: SpecialSkillAsset.BodySlam,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.Sacrifice] = {
	asset: SpecialSkillAsset.Sacrifice,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.TempSafeZone] = {
	asset: SpecialSkillAsset.TempSafeZone,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.ForceField] = {
	asset: SpecialSkillAsset.ForceField,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.ElectricField] = {
	asset: SpecialSkillAsset.ElectricField,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.MercyRelease] = {
	asset: SpecialSkillAsset.MercyRelease,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.FishBolt] = {
	asset: SpecialSkillAsset.FishBolt,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.Dart] = {
	asset: SpecialSkillAsset.Dart,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.BatDart] = {
	asset: SpecialSkillAsset.BatDart,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.ElectricDart] = {
	asset: SpecialSkillAsset.ElectricDart,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.TridentWave] = {
	asset: SpecialSkillAsset.TridentWave,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.FireBullet] = {
	asset: SpecialSkillAsset.FireBullet,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.SunPunch] = {
	asset: SpecialSkillAsset.SunPunch,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.Decoy] = {
	asset: SpecialSkillAsset.Decoy,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.TentaBola] = {
	asset: SpecialSkillAsset.TentaBola,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.Disguise] = {
	asset: SpecialSkillAsset.Disguise,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.SwordDance] = {
	asset: SpecialSkillAsset.SwordDance,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.StrongArms] = {
	asset: SpecialSkillAsset.StrongArms,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.MosaBite] = {
	asset: SpecialSkillAsset.MosaBite,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.DragonTrident] = {
	asset: SpecialSkillAsset.DragonTrident,
	lvl: 0,
};
specialSkillAssets[SpecialSkill.Snowball] = {
	asset: SpecialSkillAsset.Snowball,
	lvl: 0,
};
