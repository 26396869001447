














import { ItemCode, ItemInfo, itemInfos } from '@/game/infos/itemInfos';
import { Global } from '@/store/globalz';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { blueprintInfos } from '@/game/infos/blueprintInfos';
import BlueprintInventory from './BlueprintInventory.vue';
import {
  hasQuestTimeStarted,
  TimeLimitedQuestCode,
} from '@/game/infos/questTimeLimited';

@Component({
  components: {
    BlueprintInventory,
  },
})
export default class CollectiblesTab extends Vue {
  public filter: string = '';
  public get items(): ItemCodeAmountPair[] {
    const inventory = userx.inventory.availables;
    const pairs: ItemCodeAmountPair[] = [];
    for (const itemCode in blueprintInfos) {
      if (Object.prototype.hasOwnProperty.call(blueprintInfos, itemCode)) {
        const bpInfo = blueprintInfos[itemCode];
        if (!bpInfo.isReward && !bpInfo.isShop) {
          pairs.push({
            itemCode: bpInfo.code,
            amount: inventory[bpInfo.code],
          });
        }
      }
    }
    return pairs;
  }

  public get uncraftedList() {
    const targets = this.filteredInventory.map((vp) => blueprintInfos[vp.itemCode].result);
    return targets.map((code) => userx.inventory.crafted.indexOf(code) === -1);
  }
  public onClick(itemInfo: ItemInfo | undefined, elementId: string) {
    if (itemInfo) {
      Global.craftModal.show(itemInfo.code);
    }
  }

  public get datas() {
    return this.items.map((f) => {
      const itemInfo = itemInfos[f.itemCode];
      return ('name ' + itemInfo.name + '  rarity ' + Global.rarityLabels[itemInfo.rarity])
        .toLocaleLowerCase();
    });
  }

  public get filteredInventory() {
    const keywords = this.filter
      .toLocaleLowerCase()
      .split(',')
      .map((k) => k.trim());
    let results = this.items;
    // results.unshift();

    results = this.filter.trim()
      ? results.filter((f, i) => {
        for (const keyword of keywords) {
          const match = keyword.charAt(0) !== '!';
          const key = keyword.replaceAll('!', '').trim();
          if (match) {
            if (this.datas[i].indexOf(key) === -1) {
              return false;
            }
          } else {
            if (this.datas[i].indexOf(key) !== -1) {
              return false;
            }
          }
        }
        return true;
      })
      : results;
    return results;
  }
}
