import {
	getModule,
	Module,
	VuexModule,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import store from '@/store';
import { Dictionary } from 'vue-router/types/router';
import { ServerList } from '@/store/api/serverList';
import { ServerData, ServerInfo, ServerStat } from '@/game/infos/serverInfos';
import { MaintenanceInfo, VersionInfo } from '@/game/infos/firestoreFiles';
import userx from './userx';
import { Global } from '../globalz';
import { FishType } from '@/game/infos/fishInfos';
import { wait } from '@/util/wait';
import { SkinBannerType } from '@/game/infos/skinBannerInfos';
import { SkinGroup } from '@/game/infos/skinInfos';
import { AllRankings, BossRankings } from '@/game/infos/highscoreInfos';
import { ServerTime } from '../api/serverTime';
import { eventTimes, isTimeQuestTime, TimeLimitedQuestCode } from '@/game/infos/questTimeLimited';

let refreshServerTimeout: any;

@Module({
	namespaced: true,
	name: 'globalx',
	store,
	dynamic: true,
})
class GlobalModule extends VuexModule {
	public get clientVersionString() {
		const version = this.clientVersion;
		return `${version.major}.${version.minor}.${version.patch}`;
	}

	public get authenticated() {
		return userx.loginStatus !== 'loading';
	}

	public get recommendedServer(): ServerData | null {

		if (!this.firstPingTestReceived || !this.userDocsReady) { return null; }
		const servers = this.fastestServers.filter((s) => !s.isOffline);
		if (servers.length === 0) { return null; }
		const lastPlay = userx.userDoc.lastPlay;

		const lastServer = userx.userDoc.lastServer;
		if (lastServer && Date.now() - lastPlay < 15 ** 60000) {
			if (this.serverList[lastServer] && !this.serverList[lastServer].isOffline) { return this.serverList[lastServer]; }
			const list = this.assortedServerList;
			const [continent, region, index1, index2] = lastServer.split('|');
			if (list[continent]) {
				if (list[continent][region]) {
					const list2 = Object.values(list[continent][region]).filter((s) => !s.isOffline);
					if (list2.length > 0) {
						const fav = list2.find((s) => s.name.indexOf(`${continent}|${region}|${index1}`) === 0);
						if (fav) { return fav; }
						return list2[0];
					}
				}
			}
			const fav2 = servers.find((s) => s.name.indexOf(continent) === 0);
			if (fav2) { return fav2; }
		}
		return servers[0];
	}

	public get assortedServerList() {
		const serverList: Dictionary<Dictionary<Dictionary<ServerData>>> = {};
		for (const id in this.serverList) {
			if (Object.prototype.hasOwnProperty.call(this.serverList, id)) {
				const server = this.serverList[id];
				const [continent, region, index1, index2] = server.name.split('|');
				if (!serverList[continent]) { serverList[continent] = {}; }
				if (!serverList[continent][region]) { serverList[continent][region] = {}; }
				serverList[continent][region][`${index1}-${index2}`] = server;
			}
		}
		return serverList;
	}
	public get fastestServers() {
		const servers = Object.values(this.serverList);
		return servers.sort((a, b) => {
			if (!a.ping || a.ping === -1) { return 1; }
			if (!b.ping || b.ping === -1) { return -1; }
			return a.ping - b.ping;
		});
	}
	public get forceUpdate() {
		return this.latestVersion.major > this.clientVersion.major || this.latestVersion.minor > this.clientVersion.minor || this.latestVersion.patch > this.clientVersion.patch;
	}
	public clientVersion: VersionInfo = {
		major: 1,
		minor: 76,
		patch: 1,
	};
	public latestNews = '1.76.1';
	public get latestNewSkin() {
		return this.newSkinGroup.toFixed(0) + '-' + this.newSkins.join('-');
	}
	public get hasMaintenance() {
		return (
			process.env.NODE_ENV !== 'development' &&
			location.hostname !== 'test8845.stabfish2.io' &&
			(this.maintenanceInfo?.ongoing || this.forceUpdate || false)
		);
	}
	public get autoTimeLimitedEvent(): TimeLimitedQuestCode {
		if (isTimeQuestTime(this.time, TimeLimitedQuestCode.ComingHalloween)) {
			return TimeLimitedQuestCode.ComingHalloween;
		} else if (isTimeQuestTime(this.time, TimeLimitedQuestCode.Halloween2024)) {
			return TimeLimitedQuestCode.Halloween2024;
		} else if (isTimeQuestTime(this.time, TimeLimitedQuestCode.PostHalloween)) {
			return TimeLimitedQuestCode.PostHalloween;
		}  else if (isTimeQuestTime(this.time, TimeLimitedQuestCode.ComingXmas)) {
			return TimeLimitedQuestCode.ComingXmas;
		} else if (isTimeQuestTime(this.time, TimeLimitedQuestCode.Xmas2022)) {
			return TimeLimitedQuestCode.Xmas2022;
		} else if (isTimeQuestTime(this.time, TimeLimitedQuestCode.PostXmas)) {
			return TimeLimitedQuestCode.PostXmas;
		}  else if (isTimeQuestTime(this.time, TimeLimitedQuestCode.ComingCny)) {
			return TimeLimitedQuestCode.ComingCny;
		} else if (isTimeQuestTime(this.time, TimeLimitedQuestCode.Cny2024)) {
			return TimeLimitedQuestCode.Cny2024;
		} else if (isTimeQuestTime(this.time, TimeLimitedQuestCode.PostCny)) {
			return TimeLimitedQuestCode.PostCny;
		}  else if (isTimeQuestTime(this.time, TimeLimitedQuestCode.ComingAprilFool)) {
			return TimeLimitedQuestCode.ComingAprilFool;
		}  else if (isTimeQuestTime(this.time, TimeLimitedQuestCode.ComingPirateDay)) {
			return TimeLimitedQuestCode.ComingPirateDay;
		}  else if (isTimeQuestTime(this.time, TimeLimitedQuestCode.PirateDay)) {
			return TimeLimitedQuestCode.PirateDay;
		}  else if (isTimeQuestTime(this.time, TimeLimitedQuestCode.PostPirateDay)) {
			return TimeLimitedQuestCode.PostPirateDay;
		}
		return 0;
	}
	public get autoTimeLimitedEventTime() {
		return this.autoTimeLimitedEvent ? eventTimes[this.autoTimeLimitedEvent][0] : 0;
	}
	public latestContest = 1;

	public newSkinGroup = SkinGroup.First;
	public newSkins: FishType[] = [58, 59, 60];

	public latestVersion: VersionInfo = { ...this.clientVersion };
	public switch = true;
	public maintenanceInfo: MaintenanceInfo | null = null;
	public hadMaintenance = false;
	public userDocsReady = false;
	public serverTimeReady = false;
	public serverListReady = false;
	public firstPingTestReceived = false;
	public previousPing = 0;
	public warnedMaintenance = false;
	public preventChallengeToast = false;
	public showCrazyGamesButtons = false;

	public mainMenuName = '';

	public selectedServer: ServerInfo | null = null;

	public time = 0;
	public showingUnlock = 0;
	public showingGachaAnimation = false;
	public toShowNextRevealButton = false;

	public rankings: AllRankings | null = null;
	public bossRankings: Dictionary<Dictionary<BossRankings>> | null = null;

	public lobbyServerAddress =
		// process.env.NODE_ENV !== 'development' ? 'https://lobbyserver.stabfish2.io' : 'http://192.168.1.100:9000';
		'https://lobbyserver.stabfish2.io';
	public dataServerAddress =
		// process.env.NODE_ENV !== 'development' ? 'https://dataserver4.stabfish2.io' : 'http://192.168.1.100:9001';
		'https://dataserver4.stabfish2.io';

	public serverList: Dictionary<ServerData> = {};
	@Action
	public async updateSwitch(value: boolean) {
		this.m_updateSwitch(value);
	}
	@Action
	public async updateMaintenanceInfo(value?: MaintenanceInfo) {
		this.m_updateMaintenanceInfo(value || {
			ongoing: false,
			start: -1,
			end: -1,
		});
	}
	@Action
	public async updateLatestVersion(value?: VersionInfo) {
		this.m_updateLatestVersion(value || { ...this.clientVersion });
	}
	@Action
	public async setUserDocsReady(value: boolean) {
		this.m_setUserDocsReady(value);
	}
	@Action
	public async setServerTimeReady() {
		this.m_setServerTimeReady();
	}
	@Action
	public async updateServerList(value: Dictionary<ServerData>) {
		const servers: Dictionary<ServerData> = {};
		for (const id in value) {
			if (Object.prototype.hasOwnProperty.call(value, id)) {
				const info = value[id];
				if (servers[id]) {
					servers[id] = { ...servers[id], ...info };
				} else {
					servers[id] = info;
					servers[id].ping = -1;
					servers[id].numPlayers = 0;
					servers[id].isOffline = false;
				}

			}
		}
		this.m_updateServerList(servers);
		if (!this.firstPingTestReceived) {
			ServerList.pingAll();
		}
		for (const serverId in servers) {
			if (Object.prototype.hasOwnProperty.call(servers, serverId)) {
				const sData = servers[serverId];
				if (sData.armageddonStartTime) {
					if (refreshServerTimeout) {
						clearTimeout(refreshServerTimeout);
					}
					const timeLeft = sData.armageddonStartTime - ServerTime.time;
					refreshServerTimeout = setTimeout(async () => {
						await ServerList.sync();
						ServerList.pingAll();
					}, timeLeft + 1000);
					break;
				}
			}
		}
	}
	@Action
	public async updateServerStat(value: ServerStat) {
		const id = value.id;
		const servers = this.serverList;
		if (servers[id]) {
			servers[id] = { ...servers[id], ...value };
		}
		this.m_updateServerList(servers);
		this.m_firstPingTestReceived();
	}
	@Action
	public async updateSelectedServer(value: ServerData | null) {
		this.m_updateSelectedServer(value);
	}

	@Action
	public async updateWarnedMaintenance(value: boolean) {
		this.m_updateWarnedMaintenance(value);
	}
	@Action
	public async showUnlock(value: FishType) {
		this.m_showUnlock(value);
	}
	@Action
	public async updateMainMenuName(value: string) {
		this.m_updateMainMenuName(value);
	}
	@Action
	public async updatePreventChallengeToast(value: boolean) {
		this.m_updatePreventChallengeToast(value);
	}
	@Action
	public async updateShowingGachaAnimation(value: boolean) {
		this.m_updateShowingGachaAnimation(value);
	}
	@Action
	public async updateToShowNextRevealButton(value: boolean) {
		this.m_updateToShowNextRevealButton(value);
	}
	@Action
	public async updateRankings(value: AllRankings) {
		this.m_updateRankings(value);
	}
	@Action
	public async updateBossRankings(value: Dictionary<Dictionary<BossRankings>>) {
		this.m_updateBossRankings(value);
	}
	@Action
	public async loadBossRankings(value: { boss: number, difficulty: number; }) {
		const lobbyServerAddress = this.lobbyServerAddress;
		try {
			const response = await fetch(`${lobbyServerAddress}/bossHighscores/${value.boss}/${value.difficulty}?v=ae`, {
				method: 'GET',
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json();
			const ranking: Dictionary<Dictionary<BossRankings>> = { ...this.bossRankings } || {};
			if (!ranking[value.boss]) { ranking[value.boss] = {}; }
			ranking[value.boss][value.difficulty] = { players: json.players, records: json.records };
			this.m_updateBossRankings(ranking);
		} catch (error) {
			throw error;
		}
	}

	@Action
	public async updateTime(value: number) {
		this.m_updateTime(value);
	}
	@Action
	public async updateShowCrazyGames(value: boolean) {
		this.m_updateShowCrazyGames(value);
	}
	@Action
	public async updateHadMaintenance(value: boolean) {
		this.m_updateHadMaintenance(value);
	}
	@Mutation
	protected m_updateHadMaintenance(value: boolean) {
		this.hadMaintenance = value;
	}
	@Mutation
	protected m_updateShowCrazyGames(value: boolean) {
		this.showCrazyGamesButtons = value;
	}
	@Mutation
	protected m_updateRankings(value: AllRankings) {
		this.rankings = value;
	}
	@Mutation
	protected m_updateBossRankings(value: Dictionary<Dictionary<BossRankings>>) {
		this.bossRankings = value;
	}
	@Mutation
	protected m_updateMainMenuName(value: string) {
		this.mainMenuName = value;
	}
	@Mutation
	protected m_updatePreventChallengeToast(value: boolean) {
		this.preventChallengeToast = value;
	}
	@Mutation
	protected m_updateWarnedMaintenance(value: boolean) {
		this.warnedMaintenance = value;
	}
	@Mutation
	protected m_showUnlock(value: FishType) {
		this.showingUnlock = value;
	}
	@Mutation
	protected m_updateSwitch(value: boolean) {
		this.switch = value;
	}
	@Mutation
	protected m_updateServerList(value: Dictionary<ServerData>) {
		this.serverList = value;
		this.serverListReady = true;
		if (this.selectedServer && !value[this.selectedServer.id]) {
			this.selectedServer = null;
		}
	}
	@Mutation
	protected m_updateMaintenanceInfo(value: MaintenanceInfo) {
		this.maintenanceInfo = value;
	}
	@Mutation
	protected m_updateLatestVersion(value: VersionInfo) {
		this.latestVersion = value;
	}
	@Mutation
	protected m_setUserDocsReady(value: boolean) {
		this.userDocsReady = value;
	}
	@Mutation
	protected m_setServerTimeReady() {
		this.serverTimeReady = true;
	}
	@Mutation
	protected m_firstPingTestReceived() {
		this.firstPingTestReceived = true;
		this.previousPing = Date.now();
	}
	@Mutation
	protected m_updateSelectedServer(value: ServerData | null) {
		this.selectedServer = value;
	}
	@Mutation
	protected m_updateShowingGachaAnimation(value: boolean) {
		this.showingGachaAnimation = value;
	}
	@Mutation
	protected m_updateToShowNextRevealButton(value: boolean) {
		this.toShowNextRevealButton = value;
	}
	@Mutation
	protected m_updateTime(value: number) {
		this.time = value;
	}
}


export default getModule(GlobalModule);
